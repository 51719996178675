import React, { useState } from "react";
import "./Traffic.css";
import AppButton from "../AppButton/AppButton";
import BackButton from "../BackButton/BackButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import { setDirection } from "../../redux/actions/actions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { parseTimestamp, getBonus, roundTraffic, getRefTraffic, getIsFree, getTariffEnded, getOnlyOwn } from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";
import cross from "../../images/crossTraffic.svg";

function Traffic() {
    const currentUser = useSelector((state) => state.currentUser);
    const language = useSelector((state) => state.language);
    const remoteData = useSelector((state) => state.remoteData);
    // 1679077973
    // const currentUser = {
    //   tariff: 'nolimit',
    //   endActiveDate: 	1679077973 * 1000,
    //   bonusDate: 1677921307591.322,
    //   smart: 0,
    //   smartTraffic: 0,
    //   regularTraffic: 5,
    //   bonusTrafficNow: '5_regular',
    //   bonusTrafficNextMonth: 0,
    //   referralTraffic: 0,
    //   daysLeft: 5,
    //   trafficMean: 0,
    //   trafficForecast: 0
    // }

    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);

    const today = new Date();
    const isNolimit = currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit";
    const isNolimitReferral = currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() === "nolimit" && currentUser.endActiveDate < today && currentUser.referralTraffic > 0;

    const usedTrafficStats = !isNolimit ? currentUser?.usedTrafficStats.reverse() : false;

    const reveresed = usedTrafficStats ? [...usedTrafficStats].reverse() : [];

    const isFree = getIsFree(currentUser, getBonus);
    const isEmpty = getTariffEnded(currentUser, getBonus, isFree);
    const onlyOwn = getOnlyOwn(currentUser, isEmpty, remoteData?.data?.endDate);
    const nextFit = !isNaN(Number(currentUser.firstFitTariff)) && currentUser.firstFitTariff.trim() !== "" && typeof currentUser.firstFitTariff !== "string" ? currentUser.firstFitTariff : 0;

    const direction = useSelector((state) => state.direction);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}.${month}`;
    };

    return (
        <motion.section
            className="traffic"
            initial={direction ? "fromLeft" : "fromRight"}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
            exit={direction ? "exitToRight" : "exitToLeft"}
            variants={directionVariants}
        >
            <BurgerMenu color="var(--white)" />
            <BackButton text="Назад" path={-1} currentClass="white" title="Трафик" />
            <div className="empty-block"></div>
            <div className="traffic__main">
                {(isNolimit && !isNolimitReferral)  ? (
                    <div className="traffic__main-content">
                        <span className="traffic__main_text">
                            {currentUser.endActiveDate < today || remoteData.endDate < today
                                ? translations[language].textTips.tariff + translations[language].tariffes.tariffEnded
                                : translations[language].textTips.activeUntil + (onlyOwn ? parseTimestamp(remoteData.endDate) : parseTimestamp(currentUser.endActiveDate))}
                        </span>
                        {onlyOwn ? <span className="traffic__main_value nolimit">{remoteData?.data?.trafficTotal}</span> : <span className="traffic__main_value nolimit">NOLIMIT</span>}
                        <span className="traffic__main_text">{translations[language].traffic.nolimitTip}</span>
                    </div>
                ) : !isEmpty || isNolimitReferral ? (
                    <div className="traffic__main-content">
                        <span className="traffic__main_text">{isNolimitReferral ? "" : translations[language].traffic.enoughTo + parseTimestamp(currentUser.endDate)}</span>
                        <span className="traffic__main_value">
                            {(currentUser.smartTraffic + currentUser.regularTraffic + parseInt(getRefTraffic(currentUser.referralTraffic)) + nextFit).toFixed(1) + translations[language].textTips.gb}
                        </span>
                        <span className="traffic__main_text">{translations[language].traffic.toEnd}</span>
                    </div>
                ) : (
                    <div className="traffic__main-content">
                        <span className="traffic__main_value empty">{translations[language].traffic.empty}</span>
                    </div>
                )}
                {isNolimit ? null : (
                    <div className="traffic__outlook">
                        <div className="traffic__outlook-element">
                            <span className="traffic__outlook-element-text">{translations[language].traffic.averagePerDay}</span>
                            <span className="traffic__outlook-element-value">{(isEmpty && !onlyOwn ? "0" : currentUser.trafficMean) + translations[language].textTips.gb}</span>
                        </div>
                        <div className="traffic__outlook-element">
                            <span className="traffic__outlook-element-text">{translations[language].traffic.forecast}</span>
                            <span className="traffic__outlook-element-value">{(isEmpty && !onlyOwn ? "0" : currentUser.trafficForecast) + translations[language].textTips.gb}</span>
                        </div>
                    </div>
                )}
                {usedTrafficStats && (
                    <div className="traffic__history" onClick={() => setVisible(true)}>
                        <span className="traffic__history-title">
                            {translations[language].traffic.history1} <br /> {translations[language].traffic.history2}
                        </span>
                        <div className="traffic__history-dots">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}

                {usedTrafficStats && (
                    <div className={`traffic__history-item ${visible ? "visible" : ""}`}>
                        <div className="traffic__history-cross">
                            <img className="traffic__history-cross-img" src={cross} onClick={() => setVisible(false)} />
                        </div>
                        <div className="traffic__history-label">
                            <span className="traffic__history-label-item">{translations[language].traffic.historyLabel1}</span>
                            <span className="traffic__history-label-item">{translations[language].traffic.historyLabel2}</span>
                            <span className="traffic__history-label-item">{translations[language].traffic.historyLabel3}</span>
                        </div>

                        <div className="traffic__history-days">
                            {reveresed.map((item, idx) => {
                                const balance = getRefTraffic(item.balance) > 0 ? getRefTraffic(item.balance) : 0;
                                return (
                                    <div className="traffic__history-day" key={idx}>
                                        {idx + 1 === reveresed.length && <span className="traffic__history-tag">{translations[language].traffic.active}</span>}
                                        <div className="traffic__history-items">
                                            <span className="traffic__history-label-item">{formatDate(item.date)}</span>
                                            <span className="traffic__history-label-item">{getRefTraffic(item.dailyTraffic) + translations[language].textTips.gb}</span>
                                            <span className="traffic__history-label-item">{balance + translations[language].textTips.gb}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className="traffic__secondary-content">
                {isNolimit ? (
                    <AppButton
                        text={translations[language].appButton.continueTariff}
                        currentClass="primary rose wide margin-bottom"
                        handler={() => {
                            setDirection(true);
                            navigate("/tariffes/nolimit");
                        }}
                    />
                ) : (
                    <>
                        <AppButton
                            text={translations[language].appButton.addGb}
                            currentClass={`primary rose wide margin-bottom ${isNolimit ? "disabled" : ""}`}
                            handler={() => {
                                setDirection(true);
                                currentUser.activeUser ? navigate("/tariffes/fit") : navigate("/tariffes");
                            }}
                        />
                        <div className="traffic__button-box">
                            <AppButton
                                text={translations[language].appButton.earn}
                                currentClass="secondary white narrow"
                                handler={() => {
                                    setDirection(true);
                                    navigate("/referral");
                                }}
                            />
                            <AppButton
                                text={translations[language].appButton.changeTariff}
                                currentClass="secondary white narrow"
                                handler={() => {
                                    setDirection(true);
                                    navigate("/tariffes");
                                }}
                            />
                        </div>
                    </>
                )}
                <p className="traffic__tips">
                    {/* {translations[language].traffic.dontWorry}
          <span
            onClick={() => {
              setDirection(true);
              navigate('/balance');
            }}
            className='traffic__link'
          >
            {translations[language].traffic.addBalance}
          </span>
          {translations[language].traffic.startAutoCharge} */}
                    {translations[language].traffic.dontWorry}
                </p>
            </div>
        </motion.section>
    );
}

export default Traffic;
