import React from "react";
import "./TariffFit.css";
import TariffesTemplate from "../TariffesTemplate/TariffesTemplate";
import FormLabel from "../FormLabel/FormLabel";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { translations } from "../../utils/translations/translations";

function TariffFit({
  handler,
  value,
  setValue,
  error,
  setIsHidden,
  setIsGbHidden,
  isWithRoboBalance,
  onSetIsWithRoboBalance,
  setIsRecommendHidden,
  setIsMoreHidden,
  handleResPrice,
}) {
  const prices = useSelector((state) => state.prices);
  const currentUser = useSelector((state) => state.currentUser);
  const [smart, setSmart] = React.useState(false);
  const language = useSelector((state) => state.language);
  const [active, setActive] = React.useState();
  const [resPrice, setResPrice] = React.useState(0);
  const [fullResPrice, setFullResPrice] = React.useState(0);
  const testDiscount = null;

  const handleChecked = () => {
    setSmart((curr) => !curr);
  };

  React.useEffect(() => {
    if (active === "Fix_3") {
      setFullResPrice(0);
    }
    if (active === "Fix_10") {
      smart
        ? setFullResPrice(Math.round((10 / 3) * prices.Fix_3[1]))
        : setFullResPrice(Math.round((10 / 3) * prices.Fix_3[0]));
    }
    if (active === "Fix_30") {
      smart
        ? setFullResPrice(Math.round((30 / 3) * prices.Fix_3[1]))
        : setFullResPrice(Math.round((30 / 3) * prices.Fix_3[0]));
    }
  }, [active, smart]);

  React.useEffect(() => {
    if (active === "Fix_3") {
      smart ? setResPrice(prices.Fix_3[1]) : setResPrice(prices.Fix_3[0]);
    }
    if (active === "Fix_10") {
      smart ? setResPrice(prices.Fix_10[1]) : setResPrice(prices.Fix_10[0]);
    }
    if (active === "Fix_30") {
      smart ? setResPrice(prices.Fix_30[1]) : setResPrice(prices.Fix_30[0]);
    }
    if (currentUser.discount !== null && currentUser.discount !== 0) {
      setResPrice((curr) =>
        Math.round((curr * (100 - parseInt(currentUser.discount))) / 100)
      );
    }
    if (isWithRoboBalance) {
      const multiplyer = currentUser.isFirstRefUser ? 2 : 2;
      setResPrice((curr) =>
        Math.max(0, curr - currentUser.refBalance * 100 * multiplyer)
      );
    }
  }, [smart, active, isWithRoboBalance]);

  handleResPrice(resPrice);

  return (
    <TariffesTemplate
      currentClass="fit"
      buttonText={translations[language].appButton.pay}
      handler={handler}
      error={error}
      setIsHidden={setIsHidden}
      setIsGbHidden={setIsGbHidden}
      setIsRecommendHidden={setIsRecommendHidden}
      onSetIsWithRoboBalance={onSetIsWithRoboBalance}
      onChangeCheck={handleChecked}
      setIsMoreHidden={setIsMoreHidden}
      fitResPrice={resPrice}
      fitFullPrice={fullResPrice}
    >
      <div className="tariffes__content-fit">
        <FormLabel
          elementValue={smart === true ? prices.Fix_3[1] : prices.Fix_3[0]}
          tariffName="Fit_3"
          name="package"
          handler={(data) => {
            setValue(data);
            setActive("Fix_3");
          }}
          currentClass={`tariff-item-fit ${smart && "smart"}`}
          title={`3 + 3 ${translations[language].textTips.gb.toUpperCase()} ${
            translations[language].textTips.fordays
          }`}
          text={
            active === "Fix_3" &&
            currentUser.trafficPerDay !== 0 &&
            3 / currentUser.trafficPerDay < 30
              ? translations[language].tariffes.fitExtra1 +
                Math.ceil(3 / currentUser.trafficPerDay) +
                translations[language].tariffes.fitExtra2 +
                currentUser.trafficPerDay +
                translations[language].tariffes.fitExtra3
              : null
          }
          valueMain={
            (smart === true ? prices.Fix_3[1] : prices.Fix_3[0]) +
            translations[language].textTips.currency
          }
          valueSecondary={translations[language].tariffes.singlePayment}
          isDiscounted={currentUser.discount ? true : false}
          // isDiscounted={disc ? true : false}
          currentUserDiscount={
            currentUser.discount === null && currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          isProfit={currentUser.discount ? true : false}
          profit={currentUser.discount}
        />
        <FormLabel
          elementValue={smart === true ? prices.Fix_10[1] : prices.Fix_10[0]}
          tariffName="Fit_10"
          name="package"
          handler={(data) => {
            setValue(data);
            setActive("Fix_10");
          }}
          currentClass={`tariff-item-fit ${smart && "smart"}`}
          title={`10 + 3 ${translations[language].textTips.gb.toUpperCase()} ${
            translations[language].textTips.fordays
          }`}
          text={
            active === "Fix_10" &&
            currentUser.trafficPerDay !== 0 &&
            10 / currentUser.trafficPerDay < 30
              ? translations[language].tariffes.fitExtra1 +
                Math.ceil(10 / currentUser.trafficPerDay) +
                translations[language].tariffes.fitExtra2 +
                currentUser.trafficPerDay +
                translations[language].tariffes.fitExtra3
              : null
          }
          valueMain={
            (smart === true ? prices.Fix_10[1] : prices.Fix_10[0]) +
            translations[language].textTips.currency
          }
          valueSecondary={translations[language].tariffes.singlePayment}
          profitValue={
            value * 1 === prices.Fix_10
              ? translations[language].tariffes.profit + (smart ? "23%" : "5%")
              : null
          }
          isRecommended={active === "Fix_10" ? true : false}
          isDiscounted={false}
          isProfit={currentUser.discount || active === "Fix_10"}
          currentUserDiscount={
            // value * 1 === prices.Fix_10 ? currentUser.discount : null
            currentUser.discount === null && currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          profit={
            currentUser.discount
              ? currentUser.discount
              : smart
              ? Math.round(
                  (1 -
                    prices.Fix_10[1] / Math.round((10 / 3) * prices.Fix_3[1])) *
                    100
                )
              : Math.round(
                  (1 -
                    prices.Fix_10[0] / Math.round((10 / 3) * prices.Fix_3[0])) *
                    100
                )
          }
        />
        <FormLabel
          elementValue={smart === true ? prices.Fix_30[1] : prices.Fix_30[0]}
          tariffName="Fit_30"
          name="package"
          handler={(data) => {
            setValue(data);
            setActive("Fix_30");
          }}
          currentClass={`tariff-item-fit ${smart && "smart"}`}
          title={`30 + 3 ${translations[language].textTips.gb.toUpperCase()} ${
            translations[language].textTips.fordays
          }`}
          text={
            active === "Fix_30" &&
            currentUser.trafficMean !== 0 &&
            30 / currentUser.trafficMean < 30
              ? translations[language].tariffes.fitExtra1 +
                Math.ceil(30 / currentUser.trafficMean) +
                translations[language].tariffes.fitExtra2 +
                currentUser.trafficMean +
                translations[language].tariffes.fitExtra3
              : null
          }
          valueMain={
            (smart === true ? prices.Fix_30[1] : prices.Fix_30[0]) +
            translations[language].textTips.currency
          }
          valueSecondary={translations[language].tariffes.singlePayment}
          isDiscounted={false}
          profitValue={
            value * 1 === prices.Fix_30
              ? translations[language].tariffes.profit + "17%"
              : null
          }
          currentUserDiscount={
            // value * 1 === prices.Fix_20 ? currentUser.discount : null
            currentUser.discount === null && currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          isRecommended={active === "Fix_30" ? true : false}
          isProfit={currentUser.discount || active === "Fix_30"}
          profit={
            currentUser.discount
              ? currentUser.discount
              : smart
              ? Math.round(
                  (1 -
                    prices.Fix_30[1] / Math.round((30 / 3) * prices.Fix_3[1])) *
                    100
                )
              : Math.round(
                  (1 -
                    prices.Fix_30[0] / Math.round((30 / 3) * prices.Fix_3[0])) *
                    100
                )
          }
        />
      </div>
    </TariffesTemplate>
  );
}
TariffFit.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handler: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  setIsHidden: PropTypes.func.isRequired,
  setIsRecommendHidden: PropTypes.func,
  setIsGbHidden: PropTypes.func,
};

export default TariffFit;
