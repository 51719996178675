import React from "react";
import TariffesTemplate from "../TariffesTemplate/TariffesTemplate";
import FormLabel from "../FormLabel/FormLabel";
import { useSelector } from "react-redux";
import { translations } from "../../utils/translations/translations";
import { getDiscountedPrice, parseTimestamp } from "../../utils/helpers";
import {
  setPaymentSmart,
  setPaymentTest,
  setOwnCountry,
} from "../../redux/actions/actions";
import SmartToggle from "../SmartToggle/SmartToggle";
import AppButton from "../AppButton/AppButton";
import { motion } from "framer-motion";
import Popup from "../Popup/Popup";
import { setNextTariff } from "../../redux/actions/actions";
import "./TariffOwn.css";
import { current } from "@reduxjs/toolkit";

function TariffOwn({
  handler,
  value,
  setValue,
  onSetIsWithRoboBalance,
  error,
  setIsHidden,
  setIsGbHidden,
  setIsRecommendHidden,
  setIsMoreHidden,
  handleResPrice,
  isWithRoboBalance,
  handleError,
  setOwnBurger,
  setOwnBurgerCountry,
  setOwnFirst,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const prices = useSelector((state) => state.prices);
  const [personCount, setPersonCount] = React.useState("");
  const [ownError, setOwnError] = React.useState("");
  const [highlitedCountry, setHighlightedCountry] = React.useState();
  const [active, setActive] = React.useState("");
  const language = useSelector((state) => state.language);
  const [progress, setProgress] = React.useState(0);
  const [isFaded, setIsFaded] = React.useState(false);
  const [smart, setSmart] = React.useState(false);
  const [isTestChecked, setIsTestchecked] = React.useState(false);
  const [resPrice, setResPrice] = React.useState(0);
  const [isConditionsHidden, setIsConditionsHidden] = React.useState(true);
  const [fullPrice, setFullPrice] = React.useState(0);
  const today = new Date();

  const toggleVariants = {
    notest: {
      x: "0%",
    },
    test: {
      x: "100%",
    },
  };

  const swipeVariants = {
    hidden: {
      x: "100%",
      opacity: 1,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: "-100%",
      opacity: 0,
    },
  };

  const addMonths = (monthsCount) => {
    const today = new Date();
    const addedMonths = today.setMonth(today.getMonth() + monthsCount);
    return parseTimestamp(addedMonths);
  };

  const handleTestCheck = () => {
    setIsTestchecked((curr) => !curr);
  };

  const handleChecked = () => {
    setSmart((curr) => !curr);
  };

  React.useEffect(() => {
    if (progress === 0) {
      setOwnFirst(true);
    } else {
      setOwnFirst(false);
    }

    if (progress === 1) {
      setOwnBurger(true);
    } else {
      setOwnBurger(false);
    }

    if (progress === 2) {
      setOwnBurgerCountry(true);
    } else {
      setOwnBurgerCountry(false);
    }
  }, [progress]);

  React.useEffect(() => {
    personCount === "me"
      ? setHighlightedCountry("1")
      : setHighlightedCountry("4");
  }, [personCount]);

  React.useEffect(() => {
    if (smart) {
      setPaymentSmart("smart");
    } else {
      setPaymentSmart("regular");
    }
  }, [smart]);

  React.useEffect(() => {
    if (isTestChecked) {
      setPaymentTest(1);
      setNextTariff(
        personCount === "me"
          ? "ownme_test"
          : personCount === "fam"
          ? "ownfam_test"
          : "owncom_test"
      );
    } else {
      setPaymentTest(0);
    }
  }, [isTestChecked, active]);

  React.useEffect(() => {
    if (progress !== 2) {
      setSmart(false);
    }
  }, [progress]);

  React.useEffect(() => {
    if (highlitedCountry === "0") {
      setOwnCountry(0);
    }

    if (highlitedCountry === "1") {
      setOwnCountry(1);
    }

    if (highlitedCountry === "2") {
      setOwnCountry(2);
    }

    if (highlitedCountry === "3") {
      setOwnCountry(3);
    }
    if (highlitedCountry === "4") {
      setOwnCountry(4);
    }
  }, [highlitedCountry]);

  React.useEffect(() => {
    let newResPrice = 0;
    if (active === "1") {
      newResPrice = getValue(1);
      //setResPrice(getValue(1));
    }

    if (active === "3") {
      newResPrice = getValue(3) * 3;
      //setResPrice(getValue(3) * 3);
    }

    if (active === "6") {
      newResPrice = getValue(6) * 6;
      //setResPrice(getValue("6") * 6);
    }

    if (active === "12") {
      newResPrice = getValue(12) * 12;
      //setResPrice(getValue("12") * 12);
    }

    if (currentUser.discount !== null && currentUser.discount !== 0) {
      newResPrice = Math.round(
        (newResPrice * (100 - parseInt(currentUser.discount))) / 100
      );
      // setResPrice((curr) =>
      //   Math.round((curr * (100 - parseInt(currentUser.discount))) / 100)
      // );
    }

    if (isTestChecked) {
      newResPrice = 100;
    }

    console.log(currentUser.refBalance * 100 * 2);

    if (isWithRoboBalance) {
      const multiplyer = currentUser.isFirstRefUser ? 2 : 2;
      newResPrice = newResPrice - currentUser.refBalance * 100 * multiplyer;
      if (newResPrice < 0) newResPrice = 0;
    }

    setResPrice(newResPrice);
  }, [smart, active, personCount, isTestChecked, isWithRoboBalance]);

  React.useEffect(() => {
    if (personCount === "me") {
      if (active === "1") {
        setFullPrice(0);
      }
      if (active === "3") {
        setFullPrice(smart ? prices.ownme_1[1] * 3 : prices.ownme_1[0] * 3);
      }

      if (active === "6") {
        setFullPrice(smart ? prices.ownme_1[1] * 6 : prices.ownme_1[0] * 6);
      }

      if (active === "12") {
        setFullPrice(smart ? prices.ownme_1[1] * 12 : prices.ownme_1[0] * 12);
      }
    }

    if (personCount === "fam") {
      if (active === "1") {
        setFullPrice(0);
      }
      if (active === "3") {
        setFullPrice(smart ? prices.ownfam_1[1] * 3 : prices.ownfam_1[0] * 3);
      }

      if (active === "6") {
        setFullPrice(smart ? prices.ownfam_1[1] * 6 : prices.ownfam_1[0] * 6);
      }

      if (active === "12") {
        setFullPrice(smart ? prices.ownfam_1[1] * 12 : prices.ownfam_1[0] * 12);
      }
    }

    if (personCount === "com") {
      if (active === "1") {
        setFullPrice(0);
      }
      if (active === "3") {
        setFullPrice(smart ? prices.owncom_1[1] * 3 : prices.owncom_1[0] * 3);
      }
      if (active === "6") {
        setFullPrice(smart ? prices.owncom_1[1] * 6 : prices.owncom_1[0] * 6);
      }
      if (active === "12") {
        setFullPrice(smart ? prices.owncom_1[1] * 12 : prices.owncom_1[0] * 12);
      }
    }
  }, [active, personCount, smart]);

  handleResPrice(resPrice);

  function handleClick() {
    if (
      (personCount === "" && progress === 0) ||
      (progress === 1 && active === "")
    ) {
      setOwnError(translations[language].tariffes.errorPackage);
      setTimeout(() => setOwnError(""), 3000);
    } else {
      setIsFaded(true);
      setTimeout(setProgress, 300, (state) => ++state);
    }
  }

  const testServers = 0;

  function handlePackageClick() {
    if (
      (personCount === "" && progress === 0) ||
      (progress === 1 && active === "")
    ) {
      setOwnError(translations[language].tariffes.errorPackage);
      setTimeout(() => setOwnError(""), 3000);
    } else {
      if (
        (personCount === "me" && currentUser.own_servers_count_small) ||
        ((personCount === "fam" || personCount === "com") &&
          currentUser.own_servers_count_big)
      ) {
        setIsFaded(true);
        setTimeout(setProgress, 300, (state) => ++state);
      }
    }
  }

  function getValue(month) {
    if (personCount === "me") {
      return month === 1
        ? smart
          ? prices.ownme_1[1]
          : prices.ownme_1[0]
        : month === 3
        ? smart
          ? prices.ownme_3[1]
          : prices.ownme_3[0]
        : month === 6
        ? smart
          ? prices.ownme_6[1]
          : prices.ownme_6[0]
        : smart
        ? prices.ownme_12[1]
        : prices.ownme_12[0];
    }
    if (personCount === "fam") {
      return month === 1
        ? smart
          ? prices.ownfam_1[1]
          : prices.ownfam_1[0]
        : month === 3
        ? smart
          ? prices.ownfam_3[1]
          : prices.ownfam_3[0]
        : month === 6
        ? smart
          ? prices.ownfam_6[1]
          : prices.ownfam_6[0]
        : smart
        ? prices.ownfam_12[1]
        : prices.ownfam_12[0];
    }
    if (personCount === "com") {
      return month === 1
        ? smart
          ? prices.owncom_1[1]
          : prices.owncom_1[0]
        : month === 3
        ? smart
          ? prices.owncom_3[1]
          : prices.owncom_3[0]
        : month === 6
        ? smart
          ? prices.owncom_6[1]
          : prices.owncom_6[0]
        : smart
        ? prices.owncom_12[1]
        : prices.owncom_12[0];
    }
  }

  const handleRoboBalance = () => {
    onSetIsWithRoboBalance();
  };

  return (
    <TariffesTemplate
      currentClass={`own`}
      onSetIsWithRoboBalance={onSetIsWithRoboBalance}
      buttonText={translations[language].appButton.pay}
      handler={handler}
      ownResPrice={resPrice}
      ownFullPrice={fullPrice}
      error={error}
      setIsHidden={setIsHidden}
      onChangeTestCheck={handleTestCheck}
      setIsGbHidden={setIsGbHidden}
      setIsRecommendHidden={setIsRecommendHidden}
      onChangeCheck={handleChecked}
      setIsMoreHidden={setIsMoreHidden}
      isPaymentHidden={progress === 2 ? false : true}
      paymentSmart={smart ? true : false}
      isSpeedHidden={progress === 2 ? true : false}
      // handleProgress={handleClick}
      ownProgress={progress}
      ownPersonCount={personCount}
      setOwnProgress={setProgress}
      backButtonOwn={true}
    >
      <div className={`tariffes-own-fields`}>
        <div className={`tariffes__content-own`}>
          {progress === 0 && (
            <motion.div
              key="progress0"
              variants={swipeVariants}
              initial="hidden"
              animate="visible"
              className="motion-div-style"
              exit="exit"
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              {/* <div className='tariff-own__speed'>
             {translations[language].tariffOwn.ownSpeed1}
             <br />
             {translations[language].tariffOwn.ownSpeed2}
        </div> */}
              <FormLabel
                elementValue={prices.ownme_12[0]}
                tariffName="ownme"
                name="package"
                handler={(data) => {
                  setValue(data);
                  setPersonCount("me");
                }}
                currentClass={`tariff-item-own`}
                title={translations[language].tariffOwn.own1Title}
                text={
                  personCount === "me" ? (
                    <>
                      {translations[language].tariffOwn.own1Text1} <br />{" "}
                      {translations[language].tariffOwn.own1Text2}
                    </>
                  ) : null
                }
                valueMain={
                  translations[language].textTips.from +
                  prices.ownme_12[0] +
                  translations[language].textTips.currencyMonth
                }
                valueSecondary={
                  translations[language].textTips.from +
                  (currentUser.discount
                    ? Math.round(
                        getDiscountedPrice(
                          prices.ownme_12[0],
                          currentUser.discount
                        ) / 3
                      )
                    : Math.round(prices.ownme_12[0] / 5)) +
                  translations[language].textTips.currencyPerson
                }
                // isDiscounted={currentUser.discount ? true : false}
                // isDiscounted={disc ? true : false}
                // currentUserDiscount={
                //   (currentUser.discount === null && currentUser.discount === 0) ? null : parseInt(currentUser.discount)
                // }
                ownPackageLimited={
                  personCount === "me"
                    ? currentUser.own_servers_count_small
                      ? false
                      : true
                    : false
                }
                ownPersons={true}
                isProfit={personCount === "me" ? true : false}
                profit={
                  currentUser.own_servers_count_small
                    ? currentUser.own_servers_count_small > 20
                      ? translations[language].tariffOwn.ownServersLeft1
                      : currentUser.own_servers_count_small < 10 &&
                        currentUser.own_servers_count_small > 0
                      ? translations[language].tariffOwn.ownServersLeft3
                      : translations[language].tariffOwn.ownServersLeft2
                    : translations[language].tariffOwn.ownServers2
                }
              />
              <FormLabel
                elementValue={prices.ownfam_12[0]}
                tariffName="ownfam"
                name="package"
                handler={(data) => {
                  setValue(data);
                  setPersonCount("fam");
                }}
                currentClass={`tariff-item-own`}
                title={translations[language].tariffOwn.own2Title}
                text={
                  personCount === "fam" ? (
                    <>
                      {translations[language].tariffOwn.own2Text1} <br />{" "}
                      {translations[language].tariffOwn.own2Text2}
                    </>
                  ) : null
                }
                valueMain={
                  translations[language].textTips.from +
                  prices.ownfam_12[0] +
                  translations[language].textTips.currencyMonth
                }
                valueSecondary={
                  translations[language].textTips.from +
                  (currentUser.discount
                    ? Math.round(
                        getDiscountedPrice(
                          prices.ownfam_12[0],
                          currentUser.discount
                        ) / 6
                      )
                    : Math.round(prices.ownfam_12[0] / 10)) +
                  translations[language].textTips.currencyPerson
                }
                // isDiscounted={currentUser.discount ? true : false}
                // isDiscounted={disc ? true : false}
                // currentUserDiscount={
                //   (currentUser.discount === null && currentUser.discount === 0) ? null : parseInt(currentUser.discount)
                // }
                ownPackageLimited={
                  personCount === "fam"
                    ? currentUser.own_servers_count_big
                      ? false
                      : true
                    : false
                }
                ownPersons={true}
                isProfit={personCount === "fam" ? true : false}
                profit={
                  currentUser.own_servers_count_big
                    ? currentUser.own_servers_count_big > 20
                      ? translations[language].tariffOwn.ownServersLeft1
                      : currentUser.own_servers_count_big < 10 &&
                        currentUser.own_servers_count_big > 0
                      ? translations[language].tariffOwn.ownServersLeft3
                      : translations[language].tariffOwn.ownServersLeft2
                    : translations[language].tariffOwn.ownServers2
                }
              />
              <FormLabel
                elementValue={prices.owncom_12[0]}
                tariffName="owncom"
                name="package"
                handler={(data) => {
                  setValue(data);
                  setPersonCount("com");
                }}
                currentClass={`tariff-item-own`}
                title={translations[language].tariffOwn.own3Title}
                text={
                  personCount === "com" ? (
                    <>
                      {translations[language].tariffOwn.own3Text1} <br />{" "}
                      {translations[language].tariffOwn.own3Text2}
                    </>
                  ) : null
                }
                valueMain={
                  translations[language].textTips.from +
                  prices.owncom_12[0] +
                  translations[language].textTips.currencyMonth
                }
                valueSecondary={
                  translations[language].textTips.from +
                  (currentUser.discount
                    ? Math.round(
                        getDiscountedPrice(
                          prices.owncom_12[0],
                          currentUser.discount
                        ) / 12
                      )
                    : Math.round(prices.owncom_12[0] / 20)) +
                  translations[language].textTips.currencyPerson
                }
                // isDiscounted={currentUser.discount ? true : false}
                // isDiscounted={disc ? true : false}
                // currentUserDiscount={
                //   (currentUser.discount === null && currentUser.discount === 0) ? null : parseInt(currentUser.discount)
                // }
                ownPackageLimited={
                  personCount === "com"
                    ? currentUser.own_servers_count_big
                      ? false
                      : true
                    : false
                }
                ownPersons={true}
                isProfit={personCount === "com" ? true : false}
                profit={
                  currentUser.own_servers_count_big
                    ? currentUser.own_servers_count_big > 20
                      ? translations[language].tariffOwn.ownServersLeft1
                      : currentUser.own_servers_count_big < 10 &&
                        currentUser.own_servers_count_big > 0
                      ? translations[language].tariffOwn.ownServersLeft3
                      : translations[language].tariffOwn.ownServersLeft2
                    : translations[language].tariffOwn.ownServers2
                }
              />
              {/* <p className='tariff-own__info'>
            {<span>{translations[language].tariffOwn.ownInEach1}</span>
            <br />
            <span>{translations[language].tariffOwn.ownInEach2}</span>
           <b>{translations[language].tariffOwn.ownTry1}</b>
           {translations[language].tariffOwn.ownTry2}
        </p> */}
              {currentUser.isFirstRefUser && currentUser.refBalance <= 0 && (
                <span className={`first_buy_hint ${"tariffes_full_price_own"}`}>
                  {translations[language].textTips.firstBuyHint}
                </span>
              )}
              <div className="tariff-own__error">{ownError}</div>
              {/* <div className='tariff-own__btn-container persons'>
          <AppButton
              // currentClass={`${(currentClass === 'fit' || currentClass === 'nolimit') && 'hidden'}
              //                 ${currentClass === 'own' && 'own'}`}
              currentClass='app-button own'
              text={translations[language].appButton.choose}
              handler={handlePackageClick}
          />
        </div>
        <div className='tariff-own_conditions-text'>{translations[language].tariffOwn.ownConditionsText}</div> */}
            </motion.div>
          )}

          {progress === 1 && (
            <motion.div
              key="progress1"
              variants={swipeVariants}
              initial="hidden"
              animate="visible"
              className="motion-div-style"
              exit="exit"
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <div className="tariff__content-own_tariffes">
                <FormLabel
                  elementValue={getValue(1)}
                  tariffName={
                    personCount === "me"
                      ? "ownme_1"
                      : personCount === "fam"
                      ? "ownfam_1"
                      : "owncom_1"
                  }
                  name="package"
                  handler={(data) => {
                    setValue(data);
                    setActive("1");
                  }}
                  currentClass={`tariff-item-own`}
                  title={translations[language].tariffOwn.own1Month}
                  text={null}
                  valueMain={
                    getValue(1) + translations[language].textTips.currency
                  }
                  valueSecondary={
                    (currentUser.discount
                      ? Math.round(
                          getDiscountedPrice(
                            getValue(1),
                            currentUser.discount
                          ) /
                            (personCount === "me"
                              ? 3
                              : personCount === "fam"
                              ? 6
                              : 12)
                        )
                      : Math.round(
                          getValue(1) /
                            (personCount === "me"
                              ? 5
                              : personCount === "fam"
                              ? 10
                              : 20)
                        )) +
                    translations[language].textTips.currencyMonth +
                    translations[language].tariffOwn.ownIfTwo1 +
                    (personCount === "me"
                      ? "5"
                      : personCount === "fam"
                      ? "10"
                      : "20") +
                    translations[language].tariffOwn.ownIfTwo3
                  }
                  // isDiscounted={disc ? true : false}
                  currentUserDiscount={
                    currentUser.discount === null && currentUser.discount === 0
                      ? null
                      : parseInt(currentUser.discount)
                  }
                  isProfit={currentUser.discount ? true : false}
                  profit={currentUser.discount}
                  // defaultChecked={true}
                />

                <FormLabel
                  elementValue={getValue(3)}
                  tariffName={
                    personCount === "me"
                      ? "ownme_3"
                      : personCount === "fam"
                      ? "ownfam_3"
                      : "owncom_3"
                  }
                  name="package"
                  handler={(data) => {
                    setValue(data);
                    setActive("3");
                  }}
                  currentClass={`tariff-item-own`}
                  title={translations[language].tariffOwn.own3Months}
                  text={null}
                  valueMain={
                    getValue(3) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={
                    (currentUser.discount
                      ? Math.round(
                          getDiscountedPrice(
                            getValue(3),
                            currentUser.discount
                          ) /
                            (personCount === "me"
                              ? 3
                              : personCount === "fam"
                              ? 6
                              : 12)
                        )
                      : Math.round(
                          getValue(3) /
                            (personCount === "me"
                              ? 5
                              : personCount === "fam"
                              ? 10
                              : 20)
                        )) +
                    translations[language].textTips.currencyMonth +
                    translations[language].tariffOwn.ownIfTwo1 +
                    (personCount === "me"
                      ? "5"
                      : personCount === "fam"
                      ? "10"
                      : "20") +
                    translations[language].tariffOwn.ownIfTwo3
                  }
                  isDiscounted={currentUser.discount ? true : false}
                  // isDiscounted={disc ? true : false}
                  currentUserDiscount={
                    currentUser.discount === null && currentUser.discount === 0
                      ? null
                      : parseInt(currentUser.discount)
                  }
                  isProfit={currentUser.discount || active === "3"}
                  profit={
                    currentUser.discount
                      ? currentUser.discount
                      : smart
                      ? personCount === "me"
                        ? Math.round(
                            (1 -
                              (prices.ownme_3[1] * 3) /
                                (prices.ownme_1[1] * 3)) *
                              100
                          )
                        : personCount === "fam"
                        ? Math.round(
                            (1 -
                              (prices.ownfam_3[1] * 3) /
                                (prices.ownfam_1[1] * 3)) *
                              100
                          )
                        : Math.round(
                            (1 -
                              (prices.owncom_3[1] * 3) /
                                (prices.owncom_1[1] * 3)) *
                              100
                          )
                      : personCount === "me"
                      ? Math.round(
                          (1 -
                            (prices.ownme_3[0] * 3) / (prices.ownme_1[0] * 3)) *
                            100
                        )
                      : personCount === "fam"
                      ? Math.round(
                          (1 -
                            (prices.ownfam_3[0] * 3) /
                              (prices.ownfam_1[0] * 3)) *
                            100
                        )
                      : Math.round(
                          (1 -
                            (prices.owncom_3[0] * 3) /
                              (prices.owncom_1[0] * 3)) *
                            100
                        )
                  }
                />
                {currentUser.own_tested || currentUser.owner ? (
                  <FormLabel
                    elementValue={getValue(6)}
                    tariffName={
                      personCount === "me"
                        ? "ownme_6"
                        : personCount === "fam"
                        ? "ownfam_6"
                        : "owncom_6"
                    }
                    name="package"
                    handler={(data) => {
                      setValue(data);
                      setActive("6");
                    }}
                    currentClass={`tariff-item-own`}
                    title={translations[language].tariffOwn.own6Months}
                    text={null}
                    valueMain={
                      getValue(6) +
                      translations[language].textTips.currencyMonth
                    }
                    valueSecondary={
                      (currentUser.discount
                        ? Math.round(
                            getDiscountedPrice(
                              getValue(6),
                              currentUser.discount
                            ) /
                              (personCount === "me"
                                ? 3
                                : personCount === "fam"
                                ? 6
                                : 12)
                          )
                        : Math.round(
                            getValue(6) /
                              (personCount === "me"
                                ? 5
                                : personCount === "fam"
                                ? 10
                                : 20)
                          )) +
                      translations[language].textTips.currencyMonth +
                      translations[language].tariffOwn.ownIfTwo1 +
                      (personCount === "me"
                        ? "5"
                        : personCount === "fam"
                        ? "10"
                        : "20") +
                      translations[language].tariffOwn.ownIfTwo3
                    }
                    isDiscounted={currentUser.discount ? true : false}
                    // isDiscounted={disc ? true : false}
                    currentUserDiscount={
                      currentUser.discount === null &&
                      currentUser.discount === 0
                        ? null
                        : parseInt(currentUser.discount)
                    }
                    isProfit={currentUser.discount || active === "6"}
                    profit={
                      currentUser.discount
                        ? currentUser.discount
                        : smart
                        ? personCount === "me"
                          ? Math.round(
                              (1 -
                                (prices.ownme_6[1] * 6) /
                                  (prices.ownme_1[1] * 6)) *
                                100
                            )
                          : personCount === "fam"
                          ? Math.round(
                              (1 -
                                (prices.ownfam_6[1] * 6) /
                                  (prices.ownfam_1[1] * 6)) *
                                100
                            )
                          : Math.round(
                              (1 -
                                (prices.owncom_6[1] * 6) /
                                  (prices.owncom_1[1] * 6)) *
                                100
                            )
                        : personCount === "me"
                        ? Math.round(
                            (1 -
                              (prices.ownme_6[0] * 6) /
                                (prices.ownme_1[0] * 6)) *
                              100
                          )
                        : personCount === "fam"
                        ? Math.round(
                            (1 -
                              (prices.ownfam_6[0] * 6) /
                                (prices.ownfam_1[0] * 6)) *
                              100
                          )
                        : Math.round(
                            (1 -
                              (prices.owncom_6[0] * 6) /
                                (prices.owncom_1[0] * 6)) *
                              100
                          )
                    }
                  />
                ) : null}

                <FormLabel
                  elementValue={getValue(12)}
                  tariffName={
                    personCount === "me"
                      ? "ownme_12"
                      : personCount === "fam"
                      ? "ownfam_12"
                      : "owncom_12"
                  }
                  name="package"
                  handler={(data) => {
                    setValue(data);
                    setActive("12");
                  }}
                  currentClass={`tariff-item-own`}
                  title={translations[language].tariffOwn.own12Months}
                  text={null}
                  valueMain={
                    getValue(12) + translations[language].textTips.currencyMonth
                  }
                  valueSecondary={
                    (currentUser.discount
                      ? Math.round(
                          getDiscountedPrice(
                            getValue(12),
                            currentUser.discount
                          ) /
                            (personCount === "me"
                              ? 3
                              : personCount === "fam"
                              ? 6
                              : 12)
                        )
                      : Math.round(
                          getValue(12) /
                            (personCount === "me"
                              ? 5
                              : personCount === "fam"
                              ? 10
                              : 20)
                        )) +
                    translations[language].textTips.currencyMonth +
                    translations[language].tariffOwn.ownIfTwo1 +
                    (personCount === "me"
                      ? "5"
                      : personCount === "fam"
                      ? "10"
                      : "20") +
                    translations[language].tariffOwn.ownIfTwo3
                  }
                  isDiscounted={currentUser.discount ? true : false}
                  // isDiscounted={disc ? true : false}
                  currentUserDiscount={
                    currentUser.discount === null && currentUser.discount === 0
                      ? null
                      : parseInt(currentUser.discount)
                  }
                  isProfit={currentUser.discount || active === "12"}
                  profit={
                    currentUser.discount
                      ? currentUser.discount
                      : smart
                      ? personCount === "me"
                        ? Math.round(
                            (1 -
                              (prices.ownme_12[1] * 12) /
                                (prices.ownme_1[1] * 12)) *
                              100
                          )
                        : personCount === "fam"
                        ? Math.round(
                            (1 -
                              (prices.ownfam_12[1] * 12) /
                                (prices.ownfam_1[1] * 12)) *
                              100
                          )
                        : Math.round(
                            (1 -
                              (prices.owncom_12[1] * 12) /
                                (prices.owncom_1[1] * 12)) *
                              100
                          )
                      : personCount === "me"
                      ? Math.round(
                          (1 -
                            (prices.ownme_12[0] * 12) /
                              (prices.ownme_1[0] * 12)) *
                            100
                        )
                      : personCount === "fam"
                      ? Math.round(
                          (1 -
                            (prices.ownfam_12[0] * 12) /
                              (prices.ownfam_1[0] * 12)) *
                            100
                        )
                      : Math.round(
                          (1 -
                            (prices.owncom_12[0] * 12) /
                              (prices.owncom_1[0] * 12)) *
                            100
                        )
                  }
                />
              </div>
              {/* <SmartToggle currentClass={'own'} handleMore={() => setIsMoreHidden(false)} onChange={handleChecked}/> */}
              {/* {(currentUser.own_tested || currentUser.owner) ? null
          : (
            <div className="tariff-own__test-toggle">
            <div className={isTestChecked === true ? `tariff-own__test-toggle__desc checked` : 'tariff-own__test-toggle__desc'}>
                <div className={`tariff-own__test-toggle__desc-title`}>
                    {translations[language].tariffOwn.ownTestTitle}
                </div>
                <div className={`tariff-own__test-toggle__desc-text`}>
                    {translations[language].tariffOwn.ownTestText} 
                </div>
            </div>
            
            <motion.div
                className={`tariff-own__test-toggle__motion ${isTestChecked ? 'own' : ''}`}
              
                onClick={handleTestCheck}>
                <motion.div
                className="tariff-own__test-toggle__motion-btn"
                variants={toggleVariants}
                animate={isTestChecked ? "test" : 'notest'}
                transition={{duration: 0.2}}
                />
            </motion.div>
        </div>
          )} */}

              {/* <div className={`tariff-own__amount tariffes-amount`}>
          <span className="tariff-own__amount-title">{translations[language].tariffOwn.ownCount}</span>
          <div className="tariff-own__amout-prices">
            <span>{resPrice} {translations[language].textTips.currency}</span>
            <span className={`tariff-own__amount-full ${fullPrice === 0 && 'hidden'}`}>{fullPrice} {translations[language].textTips.currency}</span>
          </div>
        </div> */}
              {/* <div className="tariff-own__error">{ownError}</div> */}
              <div className="tariff-own__error">{ownError}</div>
              {/* <div className='tariff-own__btn-container'>
          <AppButton
                // currentClass={`${(currentClass === 'fit' || currentClass === 'nolimit') && 'hidden'}
                //                 ${currentClass === 'own' && 'own'}`}
                currentClass={`app-button own ${smart && 'own-smart'}`}
                text={translations[language].appButton.next}
                handler={handleClick}
          />
        </div>
        <div className='tariff-own_conditions-text'>{translations[language].tariffOwn.ownGuaranteeAlways}</div> */}
            </motion.div>
          )}

          {progress === 2 && (
            <motion.div
              key="progress2"
              variants={swipeVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="tariff-own__country"
            >
              <div className="tariff-own__title">
                {translations[language].tariffOwn.ownChoose}
                <br />
                {translations[language].tariffOwn.ownCountry}
              </div>
              <div className="tariff-own__country-box">
                <div className="tariff-own__country-box__row">
                  <div
                    onClick={() => setHighlightedCountry("3")}
                    className={`tariff-own__country-item ${
                      highlitedCountry === "3" && "highlighted"
                    }`}
                  >
                    {translations[language].tariffOwn.ownCalifornia}
                  </div>
                  <div
                    onClick={() => setHighlightedCountry("1")}
                    className={`tariff-own__country-item ${
                      highlitedCountry === "1" && "highlighted"
                    }`}
                  >
                    {translations[language].tariffOwn.ownNetherlands}
                  </div>
                </div>
                <div className="tariff-own__country-box__row">
                  <div
                    onClick={() =>
                      setHighlightedCountry(personCount === "me" ? "2" : "0")
                    }
                    className={`tariff-own__country-item ${
                      (highlitedCountry === "2" || highlitedCountry === "0") &&
                      "highlighted"
                    }`}
                  >
                    {personCount === "me"
                      ? translations[language].tariffOwn.ownNY
                      : translations[language].tariffOwn.ownEngland}
                  </div>
                  {personCount === "me" ? null : (
                    <div
                      onClick={() => setHighlightedCountry("4")}
                      className={`tariff-own__country-item ${
                        highlitedCountry === "4" && "highlighted"
                      }`}
                    >
                      {translations[language].tariffOwn.ownSweden}
                    </div>
                  )}
                </div>
              </div>
              <div className="tariff-own__options">
                {isTestChecked ? (
                  <span>
                    {translations[language].tariffOwn.ownTest1}
                    {parseTimestamp(today.setDate(today.getDate() + 1))}
                    {translations[language].tariffOwn.ownTest2}
                    {active}
                    {translations[language].tariffOwn.ownTest3}
                    {translations[language].tariffOwn.ownTest4}
                    {active === "1"
                      ? addMonths(1)
                      : active === "3"
                      ? addMonths(3)
                      : active === "6"
                      ? addMonths(6)
                      : addMonths(12)}
                  </span>
                ) : null}
                <span>
                  {translations[language].tariffOwn.ownTariffNameOption1}
                  {personCount === "me"
                    ? "1-5"
                    : personCount === "fam"
                    ? "6-10"
                    : "11-20"}
                  {translations[language].tariffOwn.ownTariffNameOption2}
                </span>
                <span>
                  {translations[language].tariffOwn.ownPackage1}
                  {personCount === "me"
                    ? translations[language].tariffOwn.own1Title
                    : personCount === "fam"
                    ? translations[language].tariffOwn.own2Title
                    : translations[language].tariffOwn.own3Title}
                  {translations[language].tariffOwn.ownPackage2}
                  {active === "1"
                    ? translations[language].tariffOwn.own1Month
                    : active === "3"
                    ? translations[language].tariffOwn.own3Months
                    : active === "6"
                    ? translations[language].tariffOwn.own6Months
                    : translations[language].tariffOwn.own12Months}
                  {translations[language].tariffOwn.ownPackage3}
                  {active === "1"
                    ? addMonths(1)
                    : active === "3"
                    ? addMonths(3)
                    : active === "6"
                    ? addMonths(6)
                    : addMonths(12)}
                </span>
                <span>
                  {translations[language].tariffOwn.ownGuarantedSpeed}
                </span>
                <span>{translations[language].tariffOwn.ownGadgets}</span>
                <span>
                  {smart ? translations[language].tariffOwn.ownSmart : null}
                </span>
              </div>
            </motion.div>
          )}
          <Popup
            title={translations[language].tariffOwn.ownPopupTitle}
            isHidden={isConditionsHidden}
            handleHide={setIsConditionsHidden}
            currentClass={`popup-own ${progress !== 0 && "hidden"}`}
          >
            {
              <>
                <div className="tariffes__popup-overlay" />
                <div className="tariffes__popup-container">
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText1}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText2}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText3}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText4}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText5}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText6}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText7}
                  </p>
                  <p className="referral__popup-text">
                    {translations[language].tariffOwn.ownPopupText8}
                  </p>
                </div>
                <AppButton
                  text={translations[language].appButton.gotIt}
                  currentClass={`margin-top bg-white primary violet`}
                  handler={() => setIsConditionsHidden(true)}
                />
              </>
            }
          </Popup>
        </div>
      </div>
      {progress === 1 ? (
        <SmartToggle
          handleMore={() => setIsMoreHidden(false)}
          currentClass={"own"}
          onChange={handleChecked}
        />
      ) : null}
      {progress === 1 && !currentUser.own_tested && !currentUser.owner ? (
        <div className="tariff-own__test-toggle">
          <div
            className={
              isTestChecked === true
                ? `tariff-own__test-toggle__desc checked`
                : "tariff-own__test-toggle__desc"
            }
          >
            <div className={`tariff-own__test-toggle__desc-title`}>
              {translations[language].tariffOwn.ownTestTitle}
            </div>
            <div className={`tariff-own__test-toggle__desc-text`}>
              {translations[language].tariffOwn.ownTestText}
            </div>
          </div>
          <motion.div
            className={`tariff-own__test-toggle__motion ${
              isTestChecked ? "own" : ""
            }`}
            onClick={handleTestCheck}
          >
            <motion.div
              className="tariff-own__test-toggle__motion-btn"
              variants={toggleVariants}
              animate={isTestChecked ? "test" : "notest"}
              transition={{ duration: 0.2 }}
            />
          </motion.div>
        </div>
      ) : null}
      {progress === 1 && (
        <div className="tariff-own__amount own-country">
          <span className="tariff-own__amount-title">
            {translations[language].tariffOwn.ownCount}
          </span>
          <div className="tariff-own__amout-prices">
            <span>
              {resPrice} {translations[language].textTips.currency}
            </span>
            <span
              className={`tariff-own__amount-full ${
                fullPrice === 0 && "hidden"
              }`}
            >
              {fullPrice} {translations[language].textTips.currency}
            </span>
          </div>
        </div>
      )}
      <div className="tariff-own__btn-container persons">
        {progress === 0 && (
          <>
            <AppButton
              // currentClass={`${(currentClass === 'fit' || currentClass === 'nolimit') && 'hidden'}
              //                 ${currentClass === 'own' && 'own'}`}
              currentClass="app-button own"
              text={translations[language].appButton.choose}
              handler={handlePackageClick}
            />
            <div className="tariff-own_conditions-text">
              {translations[language].tariffOwn.ownConditionsText}
            </div>
          </>
        )}
        {progress === 1 && (
          <>
            <AppButton
              // currentClass={`${(currentClass === 'fit' || currentClass === 'nolimit') && 'hidden'}
              //                 ${currentClass === 'own' && 'own'}`}
              currentClass={`app-button own primary ${smart && "own-smart"}`}
              text={translations[language].appButton.next}
              handler={handleClick}
            />
            <div className="tariff-own_conditions-text">
              {translations[language].tariffOwn.ownGuaranteeAlways}
            </div>
          </>
        )}
      </div>
    </TariffesTemplate>
  );
}

export default TariffOwn;
