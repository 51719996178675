export default {
    appButton: {
        next: "Next",
        installVpn: "Install VPN",
        install: "Install",
        mainMenu: "Main Menu",
        myVpn: "My VPN",
        toTelegram: "Go to Telegram",
        backToTelegram: "Back to Telegram",
        gotIt: "I see",
        selectGift: "Select a gift",
        goToPayment: "Go to payment",
        choose: "Choose",
        addGb: "Top up GB",
        payGb: "Buy additional GB",
        payment: "Payment",
        goToPayment: "Go to payment",
        inviteFriend: "Invite a friend",
        approve: "Confirm",
        pay: "Pay",
        continueTariff: "Extend tariff",
        giftRobo: "Give Robo",
        seeGift: "Gift example",
        myTraffic: "My traffic",
        allTariffes: "All tariffs",
        earn: "Earn",
        fill: "Replenish",
        changeTariff: "Change tariff",
        turnOff: "Turn off anyway",
        keepOn: "Do not disable",
        saveOpt: "Save settings",
        autoOpt: "Auto-tuning",
        againOpt: "Configure again",
        chooseTariff: "Choose tariff",
        downloadOutline: "Download Outline",
        referralGb: "Referral GB",
        balanceProgress: "Traffic balance",
    },
    textTips: {
        fordays: "на 30 дней",
        instead: "Вместо ",
        currency: "₽",
        currencyMonth: "₽/month",
        currencyDay: " ₽/day",
        currencyText: "rubles",
        currencyTotal: "RUB total",
        currencyPerson: " ₽/person",
        percent: "%",
        currentBalance: "Current balance: ",
        firstBuyHint: "Для вас действует акция на первую покупку: удвоим любой тариф!🌟",
        referralStages: "Больше приглашений\u00A0— больше мотивация. Еще, мы удвоим все\u00A0$, если вы купите на них VPN",
        referralTerms: `Вам — от 1 до 3$ за приглашение\nдруга, кто совершит покупку.\nДругу — x2 на первую покупку\nи x2 бесплатного трафика на старте`,
        withdrawThreshold: "* вывод от $35",
        friendsReferred: "Друзей приобрели тариф",
        myVpn: "My VPN",
        activeUntil: "Active until ",
        tariffEndDate: "Estimated tariff end date",
        tariffEndDateBonus: "Tariff end date",
        enoughTo: "Enough until: ",
        tariff: "Tariff",
        enough: "enough",
        not: "not",
        from: "from ",
        notUnderstand: "I don't understand",
        notUnderstandText: "Opportunities, FAQ, news",
        instructionBtn: "VPN access key",
        instructionsBtnText: "Installation instructions",
        referral: "Referral program",
        referralBtnText: "5+5 GB",
        referralButton: "Withdraw (from $35)",
        referralBtnTemp: "The function is temporarily under repair 🫡",
        gift: "Give a VPN",
        giftBtnText: "In touch with loved ones",
        tariffes: "Tariffs",
        tariffesBtnText: "Buy, select tariff",
        gb: "GB",
        termsAndConditions: "Detailed conditions",
        balance: "Balance",
        balanceText: "Top up or spend",
        traffic: "Traffic",
        trafficText: "Remaining GB",
        options: "Customize",
        optionsText: "Configure manually",
        support: "Support",
        supportText: "Write to us",
        noResponce: "No response?",
        noResponceText: "Write to us!",
        faq: "FAQ",
        faqText: "Answers to questions",
        news: "News",
        newsText: "Updates from robo",
        possibilities: "Possibilities",
        possibilitiesText: "Top 5 reasons why robo",
        ourValues: "Our values",
        valuesText: "Why robo",
        values: "Values",
        valuesRobo: "Robo values",
        possibilitiesRobo: "Possibilities robo",
        paymentOptions: "Payment methods",
        trafficUpdateDate: "(GB update date)",
        left: "Remaining",
        on: "on",
        days: "days",
        till: "to",
        remote: "Remote",
        remoteSubText: "...release soon!",
        yourTariff: "Your tariff",
        remoteFull: "Remote control",
        remoteMenuText: "To manage the OWN tariff",
        discount: "Discount",
        freeGb: "Free GB's are over this month, the next ones are ",
    },
    remote: {
        remoteBtn1: "Main Menu",
        remoteBtn2: "Traffic",
        remoteLeft: "remaining",
        remoteUsed: "Used",
        remoteUsers1: "number of users",
        remoteUsers2: "including you",
        remoteTotal1: "Total credited",
        // remoteTotal2: 'this month',
        remoteTotal3: "c",
        remoteTotal4: "to",
        remoteTotal5: "on ",
        remoteTotal6: "person",
        remoteUsers1Title1: "Name",
        remoteUsers1Title2: "user",
        remoteUsers2Title1: "That's all",
        remoteUsers2Title2: "month GB",
        remoteUsers3Title1: "Spent in",
        remoteUsers3Title2: "this month GB",
        remoteUseOpts: "Apply settings",
        remoteInstruction: "Instruction",
        remoteBtnSendLink: "Send link to Telegram",
        remoteBtnLet: "Let him consume as much as needed",
        remoteBtnStop: "Limit consumption",
        remoteBtnStart: "Resume consumption",
        remoteBtnDelete: "Delete user",
        remoteBtnAddUser: "Add user",
        remoteDoneStart1: "User",
        remoteDoneStart2: "traffic resumed",
        remoteDoneStop1: "User",
        remoteDoneStop2: " consumption limited. Now his VPN has stopped, you can add as many GB as you want yourself or make consumption unlimited again.",
        remoteDoneDelete1: "User",
        remoteDoneDelete2: ' deleted, its link is no longer active. His available traffic was redistributed equally to the rest. You can create a new user, the link will be different."',
        remoteDoneLet1: "User",
        remoteDoneLet2: " the consumption limit within your tariff has been removed. We can set the limit again by clicking the 'Limit consumption' button.",
        remoteDoneCreated1: "User",
        remoteDoneCreated2: " added. Now it has 0 GB. Add as much as needed. If there are no free GB, then you can buy additional GB or redistribute the current ones (remove from someone and add).",
        remoteDoneNotCreated: "Oops, maximum users have been created on your tariff. Remove someone from the current ones to create a new one.",
        remoteDoneLink1: "Message for user",
        remoteDoneLink2: " with how to install VPN generated and sent to chat with robo in Telegram. Just forward it to the right user.",
        remoteNoOwn: "You do not have OWN connected to activate the screen - ",
        remoteNoOwnLink: "you can try the OWN tariff, this is your own VPN, for only 100 rubles",
        remoteNoOwnBtn: "OWN for 100 ₽",
        remoteBuyOwnBtn: "Buy OWN",
        remotePopupText1:
            "1. Try it, press it, all actions are reversible (except for deleting the user: D). But even if you deleted a user, you can create a new one and simply send the person a new link.",
        remotePopupText2:
            "2. When purchasing OWN, traffic is automatically distributed among users: 60-70% is credited, 30-40% remains on the balance. You can distribute 60-70% in your own way at any time. At least remove it completely. In any case, until you send the link to the user, no one will consume anything.",
        remotePopupText3:
            "3. When you delete a user (let’s say you purchased for 12 people, but you only need 7, so delete 5), their traffic is distributed to the balance. From there, from the available balance (remaining) - you can credit current users with more GB.",
        remotePopupText4: "4. When a user is deleted, his link is permanently deleted. If you want to give access to the same person again, you need to create a new user and a new link.",
        remotePopupText5:
            "5. In the new month, the configured limits from the previous month work the same again. That is, they are automatically transferred. If, for example, you credited user 4 with 200 GB in the current month, then next month he will also be credited with 200 GB.",
        remotePopupText6: "6. You can name each user (for convenience) as you wish: to do this, click on the name and enter the appropriate one.",
        remotePopupText7: '7. You can add GB in the same way. Click on "Accrued" for a specific user, enter the desired number.',
        remotePopupText8: "8. What's in the three dots (the meaning of the buttons):",
        remotePopupText9: "— Send a link to Telegram: send a link to the user and instructions on how to install Outline in a chat with robo. You just need to forward it directly to Telegram.",
        remotePopupText10: "— Let him consume as much as he needs: the user will consume GB until they run out within your entire OWN tariff; in general, you can do it without bothering,",
        remotePopupText11: "—Limit consumption: stop link; suddenly you decide to make money on VPN) and if you don’t get paid... you know what to do.",
        remotePopupText12: '— Delete a user: the link is deactivated and will disappear forever, gigabytes will be redistributed towards "Remaining"',
        remotePopupText13: "— Add a user: if you delete someone, you will have a free slot, and when created there will be a new link.",
        remotePopupText14: "9. Have questions? Try the test for 1 day, everything will work out, and we’ll tell you what won’t work out in a chat with robo. : )",
        remoteInprogressTitle1: "Your remote",
        remoteInprogressTitle2: "configurable",
        remoteInprogressText: "We will notify you when we are ready in Telegram. On average it takes 25 minutes. For any questions, you can also write to us in the robo chat.",
        remoteSettingsChanged: "Settings applied, all good!",
        remoteTrafficLimited: "Oops, it looks like you entered more traffic than is available, try again",
        remoteInfoPopup1: "You have an OWN tariff connected to ",
        remoteInfoPopup2: "person.",
        remoteInfoPopup3: " Traffic is distributed evenly to everyone. You can redistribute the traffic as you wish. Don't forget to click - apply settings.",
        remoteInfoPopup4: "to",
        remoteInfoPopup5: ". At this rate we charge ",
        remoteInfoPopup6: " GB every month. By default, GB is distributed evenly among users: you can redistribute the traffic yourself",
        remoteDeveloping: "OWN is still being created",
    },
    burgerMenu: {
        menuTitle: "Main sections",
        menuFaqText1: "You can always leave a feedback",
        menuFaqText2: "communication in Telegram chat with Robo, for this",
        menuSupportTip1: "just write something there,",
        menuSupportTip2: "we read everything.",
    },
    backButton: "Back",
    copyToClipboard: "Link copied, all good!",
    currentTariffWidgetEnd: "Your traffic has ended",
    news: "This section is under development. There will be useful lifehacks about using VPN and robo.",
    tariffes: {
        profit: "Benefit",
        percentage: "%",
        month: "Month",
        month3: "3 months",
        month1and3Text: "Forget about restrictions until ",
        month12: "12 months",
        month12Text: "Unlimited Matrix Year, Neo",
        month24Text: "We can say, at cost 🫡",
        fitExtra1: "Enough for ",
        fitExtra2: "days you spend",
        fitExtra3: "GB per day",
        freeTitle1: "Before",
        freeTitle2: "10 GB",
        freeTitle3: "every month",
        freeWidgetTitle: "0—10",
        freeWidgettext1: "5 GB + Smart VPN for everyone new",
        freeWidgettext2: "for users, essentially 259 ₽",
        freeWidgettext3: "for registration",
        freeWidgettext4: "every new month if",
        freeWidgettext5: "you had a purchase",
        freeWidgettext6: "in the previous month",
        singlePayment: "one-time payment",
        nolimitMonth: "NOLIMIT month",
        nolimit3Months: "3 months NOLIMIT",
        nolimit12Months: "12 months NOLIMIT",
        nolimit24Months: "24 months NOLIMIT",
        plus: " + ",
        plusSmart: "Smart VPN",
        smart: "+ Smart VPN",
        bonus: "BONUS",
        tariffEnded: " has ended ",
        getPay: "Have time to pay before ",
        getPay2: "to get another BONUS.",
        getPAyLink: "My tariff",
        toGetBonus: " To receive bonuses in the new month, pay before ",
        notEnoughTraffic: "It seems there is not enough traffic",
        enoughTraffic: "Seems like there's enough traffic",
        mainEnd: "Pay before ",
        mainEnd2: " and get Bonus",
        countNextMonth: "will be credited",
        errorPackage: "Select package!",
        errorTariff: "Choose a tariff!",
        tariffPopupTitleRobo: "Why robo?",
        tariffPopupTextRoboT1: "1. Always a money back guarantee",
        tariffPopupTextRoboA1: "If they block, we'll return the money.",
        tariffPopupTextRoboT2: "2. Smart robo",
        tariffPopupTextRoboA2: "Access to Russian and foreign sites with one click, and to the Russian Federation from abroad",
        tariffPopupTextRoboT3: "3. robo + telegram",
        tariffPopupTextRoboA3: "Always at hand, algorithms will make VPN invisible.",
        tariffPopupTextRoboT4: "4. Secure with Google",
        tariffPopupTextRoboA4: "It couldn't be safer (and more convenient) :)",
        tariffPopupTextRoboT5: "5. Free up to 10 GB",
        tariffPopupTextRoboA5: "Everyone, every month, without speed limits.",
        tariffPopupTitleFree: "0-10 GB how is this... and mine?",
        tariffPopupTextFreeNolimit1: "Your",
        tariffPopupTextFreeNolimit2: "we will add 5 GB",
        tariffPopupTextFreeNolimit5: "We have already credited 5 GB",
        tariffPopupTextFreeNolimit3: "so you can be in touch as soon as your tariff runs out.",
        tariffPopupTextFreeNolimit4: "we'll also give you a bonus to keep you in touch.",
        tariffPopupTextFreeFitNoBonus1: "We are awarding bonus GB in the new month,",
        tariffPopupTextFreeFitNoBonus2: "if you make at least one purchase in the current one.",
        tariffPopupTextFreeFitNoBonus3: "0 purchases - 0 bonuses",
        tariffPopupTextFreeFitNoBonus4: "FIT from 5 to 20 - from 5 to 10 bonus GB",
        tariffPopupTextFreeFitNoBonus5: "If FIT with Smart VPN, then the bonuses are the same.",
        tariffPopupTextFreeFitNoBonus6: "By the way, now you have ",
        tariffPopupTextFreeFitNoBonus7: ", and bonuses in the amount of ",
        tariffPopupTextFreeFitNoBonus8: "will be awarded.",
        tariffPopupTextFreeFitAndBonus: " have already been awarded. ",
        tariffPopupTextFreeNoFitAndBonus1: "Oops, it's all over for you...",
        tariffPopupTextFreeNoFitAndBonus2: "To get a BONUS in the new month, buy something before ",
        tariffPopupTextFreeT1: "—This month: you received: ",
        tariffPopupTextFreeT2: "— New 5 GB: ",
        tariffPopupTextFreeT3: " (remaining ",
        tariffPopupTextFreeT4: " days)",
        tariffPopupTextFreeT5: "— On average you consume: ",
        tariffPopupTextFreeT6: "GB/day",
        tariffPopupTextFreeT7: "robo thinks there is traffic",
        tariffPopupTextFreeT8: "until the next free GB",
        tariffPopup3gb:
            "The FIT tariff is the purchase of the required number of gigabytes for a period of 30 days. And “+ 3 GB” is..., if you are a user of the FIT tariff or have never made a payment, we are giving 3 gigabytes of free VPN every month.",
        tariffPopupTitleReccomend: "Do we recommend this?",
        tariffPopupTextReccomend:
            "We take the average consumption for the past 5 days. We take your remaining GB. We take the date of renewal of free traffic. We consider which package will suit you. You can do the math yourself, in the Traffic section, by the way.",
        tariffPopupTitle10gbAbout: "+ 10 GB - is this?",
        tariffPopupText10gbAbout:
            "On FREE and FIT tariffs, we credit 10 GB once a month if you make at least one purchase. Also, starting from the second month, the “Smart VPN” function, including access to Russian sites from abroad, is available only to FIT or NOLIMT users.",
        tariffPopupTitleGbHow: "+ GB and overall GB how is it?",
        tariffPopupTextGbHowT1: "You can calculate how much you need for 30 days and buy a package of GB. It will be valid for 30 days or until the GB is completed.",
        tariffPopupTextGbHowT2: "We also give up to 10 GB every new month if there was at least one payment in the past.",
        tariffPopupTextGbHowT3: "Next month you will receive ",
        tariffPopupTextGbHowT4: "Gb.",
        tariffSmartTitle: "Smart VPN",
        tariffSmartText: "Smart VPN - access to Russian and foreign sites in one click, no need to turn VPN on and off.",
        tariffSmartMore: "More details.",
        tariffesGurantee: "Always guaranteed return",
        tariffesSmarMoreText:
            "Robo algorithms distribute traffic with one inclusion to a foreign server or a Russian one, depending on where you want to go. This way, you don’t need to turn off your VPN to order food or access some Russian website. The function is also suitable for those who are abroad - traffic addressed to Russian sites, such as State Services or banks, will immediately go to them, through the Russian server. The cost is higher because we are paying for two servers at the same time rather than just one.",
    },
    tariffesList: {
        tariffesListFree: "Up to 10 GB of bonus traffic every month.",
        tariffesListFit: "Calculate your consumption and spend as much as you need. ",
        tariffesListNolimit: "Forget about consumption - spend without limits. ",
        tariffesListOwn1: "Your VPN with a dedicated speed of 1 gigabit, share with friends and family. ",
        tariffesListOwn2: "1 gigabit/sec., your own server for 1-12 people",
        tariffesListTips: "By selecting a tariff, the user accepts the terms of service.",
        tariffesListPopupA1:
            "1. We have four tariffs (user states): BONUS, FIT, NOLIMIT and OWN. All of them operate on servers with a bandwidth of up to 1 gigabit per second. BONUS FIT NOLIMIT - servers are shared with other users. OWN is a server for you or a group of up to 20 people. ",
        tariffesListPopupA2:
            "2. BONUS is awarded a) upon registration to all new users b) for the referral program c) for purchases of FIT packages, as well as d) after the end of the NOLIMIT tariff",
        tariffesListPopupA3: "3. Duration of the BONUS package",
        tariffesListPopupA4: "a) for a referral - forever",
        tariffesListPopupA5: "b) for everything else for 30 days from the date of accrual ",
        tariffesListPopupA6: "4. The duration of the FIT package is 30 days or a calendar month from the date of purchase.",
        tariffesListPopupA7: "5. The duration of the NOLIMIT package is one month, three months or 12 months. ",
        tariffesListPopupA8: "6. Duration of OWN packages - 1,3,6,12 months.",
        tariffesListPopupA9:
            "7. Limitations on the number of devices - three devices simultaneously on the NOLIMIT tariff or via one link, as many as you want at different times. We do not recommend sharing the link with someone - in this case, we cannot guarantee the stability of the speed and connection to the get robo service. It’s better to share the referral program or buy the OWN tariff as a group.",
        tariffesListPopupA10:
            "8. The terms of the Service strictly prohibit sharing your key (access to Outline) with anyone. If you share this link with someone, we cannot guarantee the stability of the VPN connection. You accept this condition by making a payment and using our service. ",
        tariffesListPopupA11: "9. The tariff begins to apply from the moment of payment. In addition to the gift, it is from the moment of activation to those who were given it. ",
        tariffesListPopupA12: "10. The refund guarantee for FIT and NOLIMIT plans is valid for the first 30 days after purchase. On the OWN tariff - the entire period of use. ",
        tariffesListPopupA13: "eleven. The return guarantee comes into effect in cases where ",
        tariffesListPopupA14:
            "a) we were unable to provide working access to the service within 24 hours from the moment we were blocked by the blocking initiator (can be read as “your VPN has not worked for 24 hours”). ",
        tariffesListPopupA15:
            'b) you also have "Care" notifications enabled from robo. "Care" is enabled by default. You can disable it only on an unlimited tariff (this way we can inform you about blocks and give you a working link in a few seconds).',
        tariffesListPopupA16:
            "c) you shared your working mail with us: this is for a rare exceptional case if the messenger Telegram or any other where we are stops working (this did not happen, but what may not happen). ",
        tariffesListPopupA17: "12. Funds for the unused period are subject to guarantee.",
        tariffesListPopupA18:
            "13. Refunds are made within a few business days if payment was made from a bank card. If from crypto, you must provide additional information. return address information.",
        tariffesListPopupA19:
            "14. There may be delays in refunds if there are reasons beyond Robo's control on the part of the bank or payment system. In this case, we undertake to provide confirmation of the detailed reason within 3 working days.",
        tariffesListPopupA20:
            "15. Robo, like any VPN service or Internet provider, collects general information about the user - ip, device, amount of traffic. This is necessary in order to establish an Internet connection at all. It is impossible to understand what kind of traffic you are consuming, since outline uses 256-bit encryption.",
        tariffesListPopupA21:
            "16. When purchasing FIT tariffs, GB are credited for a period of 30 days from the date of purchase. If a user buys 10 GB on the FIT tariff on the 1st and another 10 on the 5th, then the first 10 are valid until ~ the first day of the next month (that is, + 30 days), the second 10 - until ~ the 5th day of the next month.",
        tariffesListPopupA22:
            "17. Бонусы за покупку пакетов тарифа FIT начисляются каждый месяц. Расчет месяца происходит с первого числа после регистрации на сервисе или с момента окончания тарифа NOLIMIT.",
        tariffesListPopupA23:
            "18. Only one bonus is awarded in a new month - if the user purchased several FIT packages during the month. The size of the bonus will be determined by the largest purchase made in terms of value. That is, if a user bought 20 GB of regular VPN, and then 10 GB with Smart VPN functions, then 10 GB of regular traffic will be credited, and not 5 GB with Smart VPN. Because 10GB regular is more expensive than 5GB with Smart VPN.",
        tariffesListPopupA24: "29. For communication, you can write to the following addresses: t.me/getrobovpnBot or vrseryakov@gmail.com",
        tariffesListGuaratee1: "30 day return guarantee",
        tariffesListGuaratee2: "Always guaranteed money back",
    },
    tariffOwn: {
        own1Title: "For yourself",
        own1Text1: "500 GB/Month",
        own1Text2: "1-5 people",
        own2Title: "Family",
        own2Text1: "1000 GB/Month",
        own2Text2: "6-10 people",
        own3Title: "Team",
        own3Text1: "2000 GB/Month",
        own3Text2: "11-20 people",
        ownSpeed1: "Guaranteed channel for",
        ownSpeed2: "speeds 1 gigabit per second",
        ownGuarantee: "Return Guarantee. Three days of testing - 99 rubles.",
        ownInfo1: "Number of people based on server capacity. You",
        ownInfo2: "you can connect more in each tariff,",
        ownInfo3: "up to 10 people. But then brakes are possible.",
        own1Month: "1 month",
        own3Months: "3 months",
        own6Months: "6 months",
        own12Months: "12 months",
        ownIfTwo1: ", if ",
        ownIfTwo2: "person",
        ownIfTwo3: "person",
        ownChoose: "Choose",
        ownCountry: "country",
        ownSingapur: "Singapur",
        ownNewjersey: "New Jersey",
        ownAmsterdam: "Amsterdam",
        ownNetherlands: "Netherlands",
        ownEngland: "England",
        ownSweden: "Sweden",
        ownNY: "US, New-York",
        ownCalifornia: "US, California",
        ownSanFrancisco: "San Francisco",
        ownCount: "Total",
        ownTariffNameOption1: "✔️ own Tariff for ",
        ownTariffNameOption2: "person",
        ownPackage1: "✔️ Package ",
        ownPackage2: " on ",
        ownPackage3: "to",
        ownGuarantedSpeed: "✔️ Guaranteed speed 1 gigabit",
        ownGadgets: "✔️ How many devices do you need at the same time",
        ownSmart: "✔️ Smart VPN function",
        ownTest1: "✔️ Test period 1 day before ",
        ownTest2: " instead of package on ",
        ownTest3: "month",
        ownTest4: "to",
        ownTestTitle: "1 day test",
        ownTestText: "Try the OWN tariff for only 100 ₽",
        ownPopupTitle: "OWN tariff conditions",
        ownPopupText1:
            "1. The OWN tariff is your own VPN with a dedicated bandwidth of 1 gigabit per second (your own server, with your own IP). If you connect your device to a direct connection to our server with one cable, the download speed will be 1 gigabit per second. But, as a rule, there are other restrictions that reduce the speed: Wi-Fi, router, local provider. The main thing is that in the case of OWN it is definitely not a VPN :) as is often the case. ",
        ownPopupText2:
            "2. The OWN tariff is not only suitable for one person. But also for a group of up to 12 people. After payment, a relatively simple group management interface will be unlocked for you. Each user will have their own link and installation instructions. The interface is in non-working mode - you can see it in the My VPN > Remote control section. ",
        ownPopupText3:
            "3. The OWN tariff has three subscription options. Depending on the number of people. Each connection is allocated its own bandwidth and its own virtual server. Options for power and technical parameters of servers - 3",
        ownPopupText4: "a) for 1-3 people, the server has the following technical characteristics: 1 CPU / RAM 512 MB / 10 GB SSD Disk / 500 GB transfer ",
        ownPopupText5: "b) for 4-6 people, - tech. characteristics: 1 vCPU / RAM 1 GB / 25 GB SSD Disk / 1000 GB transfer",
        ownPopupText6: "c) for 7-12 people - tech. characteristics: 1 vCPU / RAM 2 GB / 55 GB SSD Disk (AMD EPYC, new generation) / 2000 GB transfer",
        ownPopupText7:
            "4. Each OWN tariff package has a large but limited number of GB. If it were not limited, it would be more expensive: perhaps we will do it later. But it seems that this is enough: the average consumption for 2022 is 50 GB per person. Of course there could be more. But 500 gigabytes for three (1000 for 6, and 2000 for 12) seems quite enough. Yes, you can balance GB between users and assign how much to whom.",
        ownPopupText8:
            "5. If you purchase the OWN tariff for more than one month, the gigabyte limit will not be credited to you immediately. And gradually, every month. For example, you bought OWN for 6 people for 3 months, this means that every month you will have a limit of 1000 gigabytes, and not 3000 gigabytes at once. That is, 1000 gigabytes will be credited once every ~ 30 days. Why? Alas, such technical limitations allow us to make cheaper prices. After exceeding this limit (1000 gigabytes as in the example), the tariff for the next GB is more expensive. In accordance with this additional tariff, we made it possible to purchase additional GB within a month if they ran out prematurely. However, we hope that the gigabyte will be more than enough. If anything, you can write to support, we can manually change the tariff for you, where there are more gigabytes per month, so that you pay at a favorable price.",
        ownConditionsText: "By choosing a tariff, you accept the terms of the service.",
        ownInEach1: "In each tariff, we calculated the limit in people based on the power of the servers.",
        ownInEach2: "You can share access with someone else, but then there may be a drop in speed.",
        ownGuaranteeAlways: "Always guaranteed back",
        ownPersons1: "For ",
        ownPersons2: "person, speed 1 gigabit per second",
        ownPersons3: "GB per month",
        ownInfoConnection: "If you have any questions or need help, please chat with get robo in Telegram",
        ownWidget1: "OWN Tariff",
        ownWidget2: "Test period: left",
        ownWidget3: "1 day",
        ownWidget4: "Test period has ended",
        ownWidget5: " Active until ",
        ownWidget6: "Tariff has ended",
        ownWidget7: "to",
        ownWidget8: "remaining",
        ownWidget9: "Next",
        ownWidget10: "day",
        ownServers1: "in stock",
        ownServers2: "Ended for now",
        ownServersBtn1: "Notify arrival",
        ownServersBtn2: "We'll let you know as soon as it appears!",
        ownTry1: "For only 100 rubles - ",
        ownTry2: "you can try",
        ownServersLeft1: "Enough available",
        ownServersLeft2: "A little in stock",
        ownServersLeft3: "In stock - low",
    },
    intro: {
        p1TitleBegin: "One setting - ",
        p1TitleEnd: "you can forget about VPN",
        p1Text: "On-off, on-off... Sound familiar? With Smart VPN, you can forget about this. Instagram, Netflix and Youtube. Government services, food delivery and VK - get robo vpn works everywhere.",
        p2TitleBegin: "Available and",
        p2TitleEnd: "free",
        p2Text: "We want basic internet access to be available to everyone. That’s why we provide a small amount of free traffic every month. If the free traffic isn’t enough, prices start at just 109 rubles.",
        p3TitleBegin: "Security",
        p3TitleEnd: "from Google",
        p3Text: "We don't install anything of our own on your phone. We suggest installing a safe and simple open source solution from Jigsaw (Google) - Outline. We are only on Telegram. ",
        p4TitleBegin: "Own VPN -",
        p4TitleEnd: "in a few clicks",
        p4Text: "Get robo allows you to make your own VPN in a few simple clicks (your own server) - on the OWN tariff. There is a dedicated speed of up to 1 Gb/sec, the ability to connect up to 20 people, an IP that is visible only to you.",
        p5TitleBegin: "Guarantee",
        p5TitleMiddle: "return",
        p5TitleEnd: "always",
        p5Text: "On FIT and NOLIMIT tariffs - the first 30 days after payment. On the OWN tariff - at least after 159 days, that is, always. ",
    },
    instruction: {
        p1TitleBegin: "1. Copy",
        p1TitleBeginAndroid: "1. Select and copy ",
        p1TitleMiddle1: " your key",
        p1TitleMiddle2: "to",
        p1TitleMiddle3: "get access",
        p1TitleEnd: " to VPN.",
        p2TitleBegin: "2. Download the app for",
        p2TitleMiddle: " your",
        p2TitleEnd: " device",
        // p2Text: 'It's safe, tested and developed with Google.',
        p2Text: "It's open-source, traffic is encrypted, developed with the participation of Google.",
        p3TitleBegin: "3. Insert the key to application",
        p3TitleSoftName1: "Streisand",
        p3TitleSoftName2: "FoXray",
        p3TitleSoftName3: "V2rng",
        p3TitleSoftName4: "v2rayN",
        //p3TitleMiddle: 'to application',
        p3TitleEnd: " according to the instructions",
        step1: "1st step",
        step2: "2nd step",
        step3: "3rd step",
        step4: "4th step",
        p4TitleBegin: "Great!",
        p4TitleEnd: "Everything works!",
        p4TextBegin: "Please remember to disable all other VPN applications. ",
        p4TextEnd: "Only one VPN can be running at a time.",
        p4NotActiveUserTips: "VPN is already working. We are setting up a personal account for you, as soon as it becomes available - we will notify you.",
    },
    errorPage: {
        errorTitle: "Oops, we'll fix it soon!",
        errorTextBegin: " In the technical application. work,",
        errorTextMiddle1: "if this brought you",
        errorTextMiddle2: "inconvenience, just write",
        errorTextMiddle3: "about this in Telegram chat with robo.",
        errorTextMiddle4: "We see and read everything, but in",
        errorTextMiddle5: "critical situations or",
        errorTextMiddle6: "where we were unable to provide",
        errorTextMiddle7: "quality service - ",
        errorTextMiddle8: "responsible",
        errorTextMiddle9: "and help.",
        errorTextPayment1: "Pay from your computer",
        errorTextPayment2: "temporarily not working,",
        errorTextPayment3: "please pay via phone",
        errorTextBalance1: "Sorry, balance replenishment function",
        errorTextBalance2: "temporarily unavailable. Let's fix it! ",
        errorTextBalance3: "Payment of tariffs is working as normal,",
        errorTextBalance4: "this can be done in the Tariffs section.",
        errorTextBalance5: "If it caused you any inconvenience - ",
        errorTextBalance6: "please write to the chat with the robot.",
        errorTextBalance7: "The inconvenience definitely deserves a discount.",
        errorTextNew1: "Oops. It seems you have",
        errorTextNew2: "unstable internet connection",
        errorTextNew3: "(we recommend disconnecting from weak ones",
        errorTextNew4: "networks, other VPNs, for example).",
        errorTextNew5: "Try logging in again. ",
        errorTextNew6: "If the problem happens again - ",
        errorTextNew7: "write to chat with robo.",
    },
    referral: {
        refTitle1: "5 GB for you - ",
        refTitle2: "5 GB for a friend",
        refSubtitle: "Easy to install, no need to wait for payment. And with the Smart VPN function as well. ",
        refWidgetInvitedUsers: "number of invited users",
        refWidgetTrafficRef: "number of GB earned from the referral program, taking into account used ones",
        refWidgetTraffic2: "Amount of GB earned <3",
        refUsersGetTraffic: "my friends received such a volume of free information thanks to you",
        refBottom: "Bonus gigabytes will be spent as soon as your main traffic ends - free starter or any paid one.",
        refTipCopyLink: "Copy the link and\u00A0share with a friend:",
        refTipUserTerms: "By participating in the referral program, you accept its terms and conditions.",
        refPopupText1: "1. For the program to work, you need to share the link with a friend, and he can simply follow it to Telegram and download the first kb. ",
        refPopupText2: "2. As soon as the invitee downloads his first KB, you and he will each receive 5 GB for using the service with the Smart VPN option. ",
        refPopupText3: '3. Traffic for the referral program will be displayed here in the "Referral Program" section. They will begin to be used immediately after your current tariff ends.',
        refPopupText4: "4. The program is only valid if the user has not previously registered with our service. Registration is a transition from Telegram to Robo (launching a bot).",
        refTitle1: "1. When exactly do I receive the money?",
        refText1:
            "You get money for each user who made any first purchase in get robo vpn. From $1 to $5 per invitation for withdrawal. Withdrawal is available for amounts from $35. Also, if the amount is less or you want it that way, you can buy $ - VPN, including as a gift to someone. And ... we will double your balance for the purchase of VPN, that is, $5 will turn into $10, and $30 into $60, and so on. As soon as an amount of $35 or more is available, you will have the option to withdraw funds. We do it within three business days, if it is USDT trc. If a bank card - then up to 7 business days. It is important that the money subject to withdrawal is received for payment no earlier than 30 days from the date of payment. This is important to prevent cheating. 30 days is the warranty period for return. That is, most likely, if you ask for a withdrawal of $30, then you will receive the amount minus the payments of the last month, they will be paid out after 30 days if the new withdrawal limit is sufficient",
        refTitle2: "2. The program is in $, but I use another currency, what is the exchange rate?",
        refText2:
            "The official or exchange rate (if the official one is not correct in your and our opinion). Later we will make $ for all calculations to make it convenient. They can be changed from your local currency.",
        refTitle3: "3. I still want to receive 5GB as before for an invitation, what should I do?",
        refText3:
            "We understand. Considering that we have introduced an account for everyone, it can be spent on purchases. The traffic that you previously received can now be bought. With the current conditions - even more. Therefore, do the same, just buy NOLIMIT and forget about traffic.\nThe previous referral program was used by 2-3%, we need to improve. 😃",
        refTitle4: "4. What are referal proposal for one who is invited?",
        refText4: "The user you invite gets X2 for any purchase and X2 for free traffic at the start.",
        refTitle5: "I'm blogger or just want to share with robo on social media",
        refText5:
            "We are very happy. One of the reasons why we made such an abstract program is to be accessible to everyone and automate, among other things, the conditions for working with partners.\n\nIt is also worth mentioning the following.\n\nFirstly, Get Robo VPN is not a service whose purpose is to violate any legislation. We provide an encrypted, that is, a secure communication channel for the safe transfer of information and compliance with the basic right - the secrecy of correspondence and personal life. According to this principle, according to such technologies, the entire Internet works - from banks to government agencies. What resources the user himself uses - we, firstly, do not know, and secondly, this is the user's choice.\n\nSecondly, according to the law of some countries, VPN advertising is prohibited (for example, in Russia). Such cases are less than 0.01%, but it is worth saying how it works. At the beginning of the violation, if they notice - you are given an order, if they do not like something at first. Then, if you do not delete the content for some time, they impose a fine. The number of such fines for all time was 1-2-3 specifically for VPN, and VPN tens of thousands). Due to the fact that banks and government agencies themselves use VPN - it is not profitable for them to ban VPN in essence.\n\nThirdly, in essence, we have an automated affiliate program, you immediately get up to $ 3 (up to $ 6, if you spend on VPN) per user, which is equal to about 50% of the purchase size get robo vpn. That is, we earn less from this purchase, because we still spend time on servers, team, support. Why is that? We count on the fact that, firstly, many users use this, secondly, that we will work with everyone for a long time, and there will be more purchases. Perhaps, later, we will even improve the conditions.\n\nFinally, but the main thing is, of course, you decide. <3",
        refTitle6: "6. 5$ are to become 10$, how exactly?",
        refText6: "Basically, you can withdraw funds, or you can buy a VPN with them. If you decide to buy a VPN, we double your balance: this only applies to purchases. Then $3 will turn into $6.",
        refTitle7: "7. After 35$, what are conditions?",
        refText7: "Conditions are the same, maximum for you. $5 for withdrawal per user.",
        refTitle8: "8. How exactly does motivation grow as the number of invited users increases?",
        refText8: "When you reach the next step of the referral program, you get more $ per user. For 1-3, $3. For 4-6, $5. For 7 and further, $7.",
        refTitle9: "9. Can I not get paid at all? (Cheating)",
        refText9:
            "No, we will always pay out the money. Even if it is a scam:). We record this money and it is in our bank. But a case of scamming is possible, yes, when the money can be paid out in a smaller amount - and we will not leave anything for ourselves...\nWhat case is considered scamming? If the amount of payments does not exceed the amount of payments that we must make - we reserve the right in such cases to pay out an amount not exceeding the amount of all purchases by invited users (if it is less than $ 35, respectively). That is, for example, if all payments are $ 1, and a person invited 10 people, it is expected that we should pay him $ 32, but we will pay $ 10. We will show you clearly, we have all the data. According to the average result now, we should have $ 60 from 10 people.\nWith scamming. We remain at zero (even in the minus, taking into account the costs of transactions and the time spent), and we pay you all the money. After that, we stop work with such a person, new payments will not be counted for you.",
    },
    gift: {
        giftVpn: "Give a VPN",
        giftP1Title1: "Give -",
        giftP1Title2: "simple",
        giftP1Subtitle1: "Select tariff",
        giftP1Subtitle2: "and give robo",
        giftP1Subtitle3: "to friends and family,",
        giftP1Subtitle4: "in a few clicks",
        giftP1Subtitle5: "in Telegram.",
        giftExplanation: "By choosing a gift, you accept the terms of service.",
        giftPopupText1: "1. For the program to work, you need to share the gift with a friend, and he can simply follow the link and register as if he were a new user.",
        giftPopupText2: "2. The paid tariff begins to operate from the moment it is activated by the user.",
        giftPopupText3:
            "3. The gift can only be returned in accordance with the general return conditions. The conditions can be found in the section - tariffs, detailed conditions. In short - only if we are blocked and robo is unable to provide working access to the service within 24 hours.",
        giftPopupText4: "4. The gift can be activated and sent once. Please check who you are sending it to.",
        giftP2Title1: "Select",
        giftP2Title2: "tariff",
        giftP3Title1: "Give",
        giftP3Title2: "simple",
        giftP3Text1: "1. Choose a gift.",
        giftP3Text2: "2. Pay.",
        giftP3Text3: "3. Forward",
        giftP3Text4: "gift in Telegram",
        giftP3Text5: "from robo.",
        giftSuccessTitle1: "Hurray, everything is ready! The gift is wrapped",
        giftSuccessTitle2: " - it's just a matter of small things.",
        giftSuccessText: "Go to Telegram with robo - send a gift to the right person.",
    },
    payment: {
        enterEmail: "Your email",
        repeatEmail: "Confirm your email",
        emailText: "To continue payment. Not (!) for spam, but for technical issues, if a telegram, for example, is bad - to contact you, this rarely happens, but it does happen.",
        paymentCardTitle: "Bank card",
        paymentCardText: "World and others",
        paymentCryptoTitle: "Crypto (in development)",
        paymentCryptoText: "Bitcoin and others",
        paymentBalanceTitle: "Pay using balance",
        paymentBalanceText: "On your balance",
        toPay: "To be paid",
        paymentSuccessTitle: "The payment was successful! Now you can set up your VPN and monitor the remaining traffic in the 'my VPN' section.",
        paymentSuccessText: "You can trust our configuration algorithm, or do everything yourself.",
    },
    subscription: {
        textFree: "3 GB free + payment per GB",
        textFit: "3 GB free + pay per GB",
        textNolimit: "No limits",
        textFitSub: "3 GB + Smart VPN free every month",
        myTariff: "My tariff",
        yourTariff: "Your tariff",
        algoRobo: "The robo algorithm thinks there is traffic",
        algoText: "before bonuses are credited",
        thisMonth: "This month you are credited with ",
        nextMonth: "Bonus",
        willCount: "will",
        thisMonthEnd: "free traffic. We credit 10 starting from the second month to those who make at least one purchase.",
        toYou: "To you",
        added: "accrued",
        amountFreeTraff: "GB of free traffic.",
        amountBonusTraffic: "GB of bonus traffic.",
        bonusRestText: "Since you have purchased a tariff ",
        restText: "Thank you for using robo <3",
        alreadyCounted: "have already been counted this month",
        toGet: "To get bonus GB in the new month, have time to pay any tariff from 10 GB to ",
        textNolimit: "No limits",
        textFit: "10 GB free + pay per GB",
        textBonus: "Up to 10 GB every month for free",
        ended: "The tariff has ended",
        nextOwn:
            "You purchased the OWN tariff and it works. You can use OWN from the Remote control section. The last tariff before OWN, which has not ended, is displayed here. Here the tariff will change as soon as the previous one ends.",
    },
    support: {
        supportFreeText1: "In order to maintain affordable prices and free traffic, we have retained the chat support option only for users of the unlimited tariff.",
        supportFreeText2: "We hope you understand.",
        supportNolimitText: "Here you can call the Care service operator in a telegram chat with robo. Write all your questions, we will answer within 24 hours.",
        supportToFaq: "Perhaps this section will help you",
        faq: "FAQ",
        callOperator: "Call operator",
        supportChat: "Support chat",
    },
    balance: {
        title: "Write off from balance (select from currency or from GB) when the current tariff ends.",
        subtitle: "Amount deposited",
        takeFromBalance: "Debit from currency",
        takeFromGb: "Write off from GB",
        recievedGb: "Gigabytes earned",
        inputTitle: "Or deposit the amount into your balance",
        balanceTip: "You can top up your balance and increase traffic or earn GB with ",
        balanceTipLink: "referral program",
        balanceProgress: "This section is under construction, please check back later. If you have any questions, write in the chat with robo.",
    },
    traffic: {
        nolimit: "NOLIMIT",
        nolimitTip: "Everything is under control, consume as much as you want, you have unlimited",
        averagePerDay: "On average per day you spend",
        forecast: "Your forecast traffic consumption per month",
        seemsLike: "It seems like you",
        enoghTraffic: "enough traffic until the end of the month",
        dontWorry: "In order not to worry, you can buy another tariff “for future use” - it will begin to operate after the end of the current tariff.",
        addBalance: "replenish balance",
        startAutoCharge: ", and click auto-charge at the current rate.",
        enoughTo: "Remaining until ",
        toEnd: "So many GB left until the end of the tariff",
        noFit: "FIT tariff has ended",
        empty: "It's a bit empty here",
        history1: "Consumption history",
        history2: "traffic for 30 days",
        historyLabel1: "Date",
        historyLabel2: "Spent",
        historyLabel3: "Remaining",
        active: "tariff activated",
    },
    options: {
        chooseLoc: "Choose location",
        more: "More details.",
        locationText:
            "Our server network operates in 54 countries. Here we display - firstly, the fastest points for you. Secondly, the most optimal for the Russian Federation, in the opinion of robo.",
        chooseDevice: "Select device",
        chooseDeviceText: "Your key is suitable for all devices, you can download the required application for an additional device in the Main Menu > ",
        chooseDeviceLink: "Outline Access Key",
        saveKey: "When you save the settings, your key will be updated",
        smart: "Smart robo",
        smartText: "Robo's algorithms redistribute traffic depending on which site you want to go to. Even with VPN enabled, Russian Federation sites are also available. Including from abroad.",
        care: "Communication with robo in Telegram",
        careText: "Robo will tell you if RKN blocks your server and immediately offer a new one, remind you about the end of the tariff, and help with installation. And not only.",
        careReminder:
            "Attention! If communication with the bot is disabled, we will contact you only in case of blocking in order to provide working access to the service. Robo will write what to click in Telegram if the connection can be improved or RKN will block your server.",
        careFreeReminder: "Communication can only be disabled on paid tariffs.",
        optSuccess: "Great, you've customized robo for yourself! New settings have been made.",
        optSuccessKey: "Great, you've customized robo for yourself. Your access key - for the new settings to work, insert the new key into the VPN application.",
        optionsTips: 'If the location changes, the forecast consumption in the "Tariff" section will be calculated correctly in 2-5 days.',
        optionsButton1: "The button is being repaired until 20.03.",
        optionsButton2: "The servers of the countries work the same and are approximately equally unloaded now.",
        optionsButton3: "However, if you experience any inconvenience, please chat with robo.",
    },
    values: {
        title1: "Reliability",
        text1Normal1:
            "The main goal of Robo VPN is to ensure that the internet works reliably and without interruptions. It aims to provide people with consistent access to the resources they consider essential to choose for themselves.",
        text1Bold1: "",
        text1Normal2: " not for the first 7 or 30 days, like most services. A ",
        text1Bold2: "for the entire period of using robo.",
        title2: "Available to everyone",
        text2Normal1: "Based on our core value of freedom, we believe that by providing extra opportunities for our users up front... this will lead to greater shared development in the future.",
        text2Bold: "That's why we give free traffic to all FIT users",
        text2Normal2:
            " (3 GB every month). If this is not enough (our founder’s mother has enough) - then prices are available from 89 RUR. Also, for example, there is a family and group tariff OWN - the prices there, per person, are also sheksi.",
        title3: "Connection",
        text3Bold1: "Ideally we would like you not to notice that there is ",
        text3Normal1: "any blocking at all or ",
        text3Bold2: "Internet restrictions",
        text3Normal2:
            ". Didn't notice that there is get robo. For example, if there is an algorithm, the Smart VPN function, with which you can not turn off the VPN on Russian services. There is an OWN tariff - with it you can forget about speed drops. ",
        title4: "Convenience",
        text4Normal1: "This is a synonym for the word service for us. ",
        text4Bold1: "We don't want you to sweat at all... ",
        text4Normal2: "and as was said, we thought about restrictions and VPN. ",
        text4Bold2: "Therefore ",
        text4Normal3: "a lot in Get robo ",
        text4Bold3: "automated",
        text4Normal4:
            ". From traffic distribution algorithms and work on local and foreign resources simultaneously (Sberbank, State Services and Netflix, Instagram) to automatic selection of the best location and built-in application and bot directly in Telegram - which will help and quickly collect feedback if something is wrong .",
        title5: "Security",
        text5Normal1: "So that you have no doubts about security... We decided not only to encrypt traffic, using open-source technologies (requiring encryption by default).",
        text5Bold1: "nowhere safer",
        text5Normal2: " We don't install anything of our own on your phone or device. We offer a third-party reliable application from Google (",
        text5Bold2: "Jigsaw",
        text5Normal3: ") - Outline. It needs to be installed once on the device, and... ",
        text5Bold3: "the Freemasons of the world are not scary",
        text5Normal4: ". Communication with us remains only at the Telegram level.",
    },
    messageUs: {
        title: "Personal support is only available on the NOLIMIT tariff.",
        text: " If you have feedback, you can always just write in a chat with robo - we read everything and try to improve. ",
    },
    possibilities: {
        title1: "One installation - you can forget about VPN",
        text1: "Turn on and off VPN, on and off... Sound familiar? With the Smart VPN feature, you can forget about turning your VPN on and off 10 times a day. Instagram, Netflix and Youtube. Government services, food delivery and VK - get robo vpn works everywhere.",
        title2: "Free every month",
        text2: "We want everyone to have basic access to a free internet. That's why we give away minimal free traffic every month. If free traffic is not enough, prices start from 89 rubles.",
        title3: "Security by Google",
        text3: "We don't install anything of our own on your phone. We suggest installing a safe and simple open source solution from Jigsaw (Google) - Outline. Outline always encrypts data.",
        title4: "Your own VPN in a few clicks",
        text4: "Get robo allows you to make your own VPN in a few simple clicks (your own server) - on the OWN tariff. There is a dedicated speed of up to 1 Gb/sec, the ability to connect up to 20 people, an IP that is visible only to you.",
        title5: "Always back guaranteed",
        text5: "On FIT and NOLIMIT tariffs - the first 30 days after payment. On the OWN tariff - at least after 159 days, that is, always. ",
    },
    faq: {
        title1: "What is a VPN?",
        text1normal1: "Virtual Private Network - ",
        text1bold1: " is a private line on the World Wide Web, which, regardless of public lines, allows you to reach some point on the Internet. ",
        text1normal2:
            " VPN is often used for security purposes (so that no one can view you on a shared line); as well as for content accessibility purposes. For example, in country Z Instagram is blocked, and you, baker Semyon, have been posting your pies on Instagram since 2015 and have formed a dense audience who need these pies on Instagram. In country F, Instagram is not blocked, and essentially using a VPN line directly to country F, he can have free access to his Instagram page, despite being blocked in country Z. Semyon, a baker, can also recommend a VPN with a line to country F to all his clients , who are used to ordering pies through Instagram in country Z. Thus, the country Z blocker can block Instagram and even the Seeds website with extremist pies in its channels, but the included private VPN allows you to transfer everything to country F, where there is freedom, in a split second and Semyon’s website, and his social networks, and pies are not in danger. Because in country F they don’t block anything at all.",
        title2: "Who are you? :)",
        text2bold1: "We don't install anything of our own on your phone",
        text2normal1:
            "We are a team of developers with prior experience in top Runet companies such as Yandex, Skyeng, and Sber.\nWe started creating this service in 2022.",
        text2bold2: "(Google) - outline",
        text2normal2:
            "We do not have access to your personal data, except for what you share in Telegram and what Telegram itself provides to us (these details can be changed if needed to remain completely anonymous). We do not install anything proprietary on your device, and all the technologies we offer (X Project and Co, Xray — VLESS — UTSL) are open source. They encrypt your data end-to-end, making it impossible to decrypt.",
        title3: "How are you different from other VPN services?",
        text3normal1:
            "There are many differences. Like other VPN services relative to us. Here you should rely on what you need. Our main goal is to provide you with free access to the Internet. Therefore, for example, we have built the system in such a way that it is very difficult and almost impossible to block (",
        text3bold1: "ShadowSocks protocol, automation, access in instant messengers",
        text3normal2: "), which is why we give ",
        text3bold2: "forever money back guarantee on the OWN tariff.",
        text3normal3:
            " Yes, the OWN tariff is your own server for you and your loved ones, with a guaranteed high-speed line of 1 gigabit per second. Among other things, we try to make the service accessible - and maintain a free version. Also convenient (!) - ",
        text3bold3: "robo will help even your parents set up from start to finish",
        text3normal4: "(just need to go to Robo), there's still ",
        text3bold4: "Smart VPN feature",
        text3normal5: "—no need to turn VPN on and off 10 times a day, you can turn it on and forget it, Russian sites also work with this function. ",
        text3bold5: "Smart VPN feature",
        text3normal6: " - you don’t need to turn the VPN on and off 10 times a day, you can turn it on and forget it, Russian sites also work with this function. ",
        text3bold6: "There are also disadvantages (for some). ",
        text3normal7: " For example, we do not provide unlimited free traffic like other services that make money from advertising or selling personal data. ",
        text3bold7:
            " We respect other VPN services and believe that each client finds his own provider. But, of course, we will not stand still, but try to improve in order to constantly provide new opportunities for our clients.",
        title4: "How does the return guarantee work? ",
        text4normal1:
            "If you are not satisfied with the quality of service, on the FIT and NOLIMIT tariffs - you can write to the chat with the robot in the first 30 days - we will return the money if we do not solve your problem. With the OWN tariff, the guarantee is valid for the entire period of using the service: if your VPN stops working, we will return the money at any time. ",
        text4normal2: "If you have questions about returns, you can write to us by email",
        text4normal3: ". In the body of the letter, please indicate your ID in tg, and in the title write the word return. Well, don’t forget the question, please :)",
        title5: "How many devices at the same time? ",
        text5normal1:
            "There are no restrictions on the FIT tariff. The NOLIMIT tariff includes three devices simultaneously. There are no restrictions on the OWN tariff. In all cases, we do not recommend sharing your personal link with someone; in this case, we cannot guarantee speed stability (one link is not technically designed for several people). You can share a referral link, then the user will have his own account. Or buy the OWN tariff - this is a special server for you, your family and friends: if you group there, the prices are 100-200 rubles per month, with steep speed and almost unlimited consumption. ",
        text5bold1: "simultaneous",
        text5normal2: "connection, it is possible to connect only 3 devices. Then there will be significant changes in speed. ",
        title6: "Can I share my Outline link with someone? (If it's not OWN, it's not worth it :) ",
        text6normal1:
            "Technically, one link on the NOLIMIT tariff is one user. If you connect even one more person to it, there may be noticeable drawdowns. And just for you. Plus, we, like you, have no guarantee that the link will not yet be shared with someone. ",
        text6bold1: "Recommended ",
        text6normal2: "share the referral link, then the user will have his own account. Or buy ",
        text6bold2: "tariff OWN ",
        text6normal3:
            "is a special server for you, your family and friends (access for 12 people): if you group, the prices are 100-200 rubles per month, with steep speed and almost unlimited consumption. ",
        text6link: "link",
        title7: "What happens if you share the link with someone? ",
        text7bold1: "As many as you like",
        text7normal1:
            "If someone else connects to the link, we cannot guarantee the stability of the VPN connection. Therefore, it is better to share the referral link with another person, or as they said above - team up and buy the OWN tariff. ",
        text7bold2: "IOS, Android, Mac, Windows, Linux and Google Chrome",
        text7normal2: ", all of them can be downloaded from the outline website. The only limitation: ",
        text7bold3: "1 user = 1 access",
        text7normal3:
            ". If the condition is violated and you share the link with someone, then for us it is fraud - we reserve the right to block access without refund, detailing the situation in direct message or with a refund, without explanation.",
        title8: "VPN doesn't work, what should I do? ",
        text8normal1: "We have done ",
        text8bold1: "checklist of what to do to make it work",
        text8bold2: "If you want to give access to your loved ones, you can simply share the robo service through the referral program or give someone a VPN through the gift section.",
        text8normal2:
            ". But first it’s worth saying. VPN may not work due to conditions not dependent on the VPN, but on the settings of the device or Internet provider, for example. We tried to take into account all cases in honor of the sheet (from popular and less complex to more complex and extremely rare). ",
        text8normal3:
            "1. Make sure you have the Outline app enabled. Our VPN is connected there. If you don't know how or you haven't even found the application on your device, go to Main Menu > Outline Link > ",
        text8link1: "go through the installation.",
        text8normal4:
            "2. Make sure that when connecting to robo VPN, all other VPNs are turned off. In the settings of your phone or other device and in Outline itself. If there were, then you need to disable all previous settings: a) from applications of other VPNs b) from phone settings c) if it did not help and you still connected another VPN - reinstall Outline (uninstall and install on the device).",
        text8normal5: "3. Make sure your plan hasn't expired. To do this, go to the section ",
        text8link2: "My VPN",
        text8normal6: " —> My tariff or Traffic.",
        text8normal7:
            "4. Make sure robo hasn't sent you any messages. For example, the terms of service prohibit sharing a link (access key to Outline) with anyone else. Robo will explain what's going on.",
        text8normal8: "5. Make sure in your device settings that the Outline application is connected to the Internet. Allowed access via cellular data.",
        text8normal9:
            "If your VPN still doesn't work, write about it in a chat with robo directly in Telegram. In general, VPN 98% - 99. (9)% is our goal for it to work - no worse than a cellular operator, for example. We strive for this.",
        text8normal11:
            "If you have a paid plan, we will connect and sort it out. Yes, please make sure that you tried steps 1-5 (we won’t do magic if they, these points, are not tried) - if you tried, then describe in maximum detail what does not work.",
        text8normal10:
            "6. Finally, try connecting to another VPN server (another country) in the list available in the My VPN section > manual setup > select country. This country is worth saving in your Outline. This can help, sometimes there is technical work on a server in one country, about once a month. ",
        title9: "I suspect that the VPN is slowing down the connection speed. What to do? ",
        text9normal1: "Your suspicions are correct! ",
        text9bold1: "Any VPN reduces the speed of your Internet connection. ",
        text9normal2:
            " Why? Because a VPN makes the data flow more difficult. Roughly, if without a VPN you go to your Internet provider, which is located nearby, within a radius of 100 km, then the VPN sends you several thousand kilometers away, and not directly. This route of thousands of kilometers takes longer than 100 km. Why walk thousands of km, and not 100 km as before? This is the essence of a VPN. Your provider, which is within a radius of 100 km, blocks some sites you need. Therefore, if you want to access them as before, then either turn off the VPN or allow speed limits.",
        text9normal3: "You ask: ",
        text9bold2: "can you still walk optimally, because there are faster VPNs? ",
        text9normal4: "Yes, you're right. ",
        text9bold3: "About how we walk - in the next paragraph",
        text9normal5:
            ". By the way, other parameters also reduce the speed, except VPN - this is your Internet provider and peak hours during the day (when too many people are on the Internet), VPN has nothing to do with it. And such cases may be the majority, in our opinion. ",
        title10: "I think that after all... it is your VPN that is slowing down the connection speed, what should you do? ",
        text10normal1: "Here it would be worth telling how we work and VPN in general. Firstly, yes, all VPNs reduce speed. This is the law. The previous paragraph is about this.",
        text10bold1: "Secondly, what are we doing to cut the speed less? ",
        text10normal2: "Speed is reduced in the following places (for everyone).",
        text10bold2: "First",
        text10normal3:
            ". Data transfer protocol - there are light protocols, there are complex and old ones. Complex and old protocol - for example, OpenVPN. It itself reduces the connection speed by up to 40%, according to various estimates. We use the lightweight, more modern ShadowSocks (SS) protocol. SS itself practically does not reduce speed, at most by a few percent, due to the fact that it encrypts your traffic in the name of security, as well as the absence of blocking (SS protocol is impossible to clearly detect and block). ",
        text10bold3: "Second",
        text10normal4:
            ".Server power. This is where the data goes (not, as before, to your neighborhood provider, but to a neighboring country where the Internet is free). Several parameters are important here, the main one being the bandwidth of the line to the server. On popular VPN providers (Nord, Express and similar), no matter how expensive a subscription for 15 years and profitable per month... You will not have high speed, because the services are tailored to the model when on one server (dedicated to it lines) - there are many users who actually share the speed among themselves. With us, if speed is important to you, you have the opportunity to buy your own server with a dedicated speed of 1 gigabit per second using the OWN tariff. This will be more than enough for you and your loved ones. ",
        text10bold4: "Third",
        text10normal5:
            "However, your local limitations still remain, with which we, for our part, cannot do anything: for example, at home you may have Wi-Fi at a speed of only 100 megabits per second, then a dedicated line of 1 gigabit makes no sense (however, OWN it may still be interesting in this case: because, for example, on Wednesdays and Thursdays, when there are a lot of users in general on the Internet, your speed will be stable, only you are there). ",
        title11: "Why does Speed test show different speeds all the time?",
        text11: "Both on the phone, and with or without VPN, right? Because the connection speed depends on a large number of factors, including how many people are simultaneously connected to the Internet (this is probably the most important thing): you can imagine a road with traffic jams during rush hour and at 3 am, empty. It's the same with the Internet. Even if you buy a gigabit server for $100 a month, it may happen that the speed will be ten times less (due to traffic jams). ",
        title12: "Still, a life hack on how to avoid traffic jams and get through faster",
        text12: "In your personal account we already provide you with the top 3 high-speed servers in relation to you. During the day there are peak hours of Internet consumption, for example. And you can try to get around them by switching between servers. Accordingly, download 3 of these servers to your device, and when you really don’t like the speed, try switching. As mentioned earlier, peak hours are not affected by VPN at all. It's just that in Korea everyone may already be asleep, and when you request traffic in Korea and not in Germany, there is a chance that you will still bypass these peak hours.",
        title13: "How to use Netflix, Steam, Spotify, Chat GPT, and also save money? ",
        text13normal1:
            "One of the purposes of a VPN is to access Internet features that are blocked in your country. This could be using the services above. This could be opening a master card bank card or a visa - for example, in the Turkish equivalent of a qiwi wallet, where you only need a phone number to register. This could be savings on subscriptions... for example, in Turkey, the cost of the above services is cheaper than in Finland. Accordingly, if you want to take advantage of these features - by connecting to a VPN in a specific country - you can do so. We do not specifically name these services (for example, where you can open a master card) or specific instructions - because",
        text13normal2:
            "a) we ethically do not share some opportunities, a la torrents (essentially, this is the use of content without the permission of the author, who worked and invested his time and money in its creation);",
        text13normal3:
            "b) sometimes these services may refuse payment and you may lose money (Turkish qiwi wallet); if we leave instructions, then we will have to bear responsibility for this wallet; ",
        text13normal4:
            "c) this is not within the scope of our focus - our task is to provide a high-quality VPN connection, and there are a million possibilities for what to do with it, and if you have the right desire, you can find working instructions and find everything you need; just google it. ",
        title14: "How is BONUS, FIT, NOLIMIT calculated? ",
        text14normal1: "BONUS",
        text14normal2: "— credited on the first day of registration for the service: 5 GB + Smart VPN function ",
        text14normal3: "— awarded for attracting a new user to the service: 5 GB + Smart VPN function (5 each to the person who invited and who was invited)",
        text14normal4: "— from 0 to 10 GB with or without Smart VPN, depending on the purchase of the FIT package: if the package was with Smart VPN, then the accrual with Smart VPN occurs; ",
        text14normal5: "—the bonus accrual date can be viewed in the My VPN section > ",
        text14link: "My tariff",
        text14normal6: "; this is the calendar month from the moment of registration or the first day after the end of the NOLIMIT tariff",
        text14normal7: "FIT",
        text14normal8: "— the package is purchased for 30 days;",
        text14normal9: "—the new package comes into force after the old one expires (that is, you can buy in advance); ",
        text14normal13: "— on the FIT package every month we add 3 GB with the Smart VPN function to all users",
        text14normal10: "NOLIMIT",
        text14normal11: "— NOLIMIT comes into effect after the earlier package ends: bonus GB, FIT (if they were credited earlier, before the purchase);",
        text14normal12: "—after completing the NOLIMIT tariff, we are giving away 5 GB with the Smart VPN function",
        title15: "How can I contact you?",
        text15: "You can always leave feedback in Telegram chat with robo. To do this, just write something there. We read everything. Among other things, the NOLIMIT and OWN tariffs have guaranteed support from a living person. ",
        title17: "What speed are your servers?",
        text17: "The throughput capacity of our servers is 1 gigabit per second. On FIT and NOLIMIT tariffs, the servers are not your own, but are shared with other users. On the OWN tariff, a line with a speed of 1 gigabit is just for you. Accordingly, there you can accelerate to such speeds. If there are no restrictions from your home Internet provider or router, for example. Your own server prevents almost all cases of unstable speed or VPN connection failure. ",
        title16: "I want a VPN without speed drops, just to be sure... ",
        text16normal1: "Regular VPNs (Express, Nord, SurfShark, etc.) put many users on one server, so connections may not be stable. This is what we do at the NOLIMIT tariff. ",
        text16normal2: "If you want a guaranteed speed channel, we have an OWN tariff, there is 1 gigabit and current for you. ",
        title17: "Why aren't you blocked like everyone else?",
        text17normal1: "When the service was created - ",
        text17bold1: "Our main goal was to build it in such a way that there would be no blocking.",
        text17normal2:
            " Accordingly, what we did.. Before answering this question, it’s worth briefly saying how blocking occurs in general. Taking into account Chinese, Russian, Indian... all, in general, known methods. ",
        text17normal3:
            "1. Blocking using Internet data transfer protocol. This, conditionally, is how messages or requests are transmitted from your device somewhere and how data, for example, videos from YouTube, comes to it. Here are the most prominent data transfer protocols, if you analyze this data. There, rudely, that’s what it says, VPN, since initially a VPN is not an invention to bypass censorship, it’s for encrypting data so that it doesn’t get hacked. In the West, for example, there are few blockings and almost all content is available, but VPN is popular precisely for maintaining data privacy. ",
        text17normal4:
            "2. IP blocking. IP is the destination where your traffic goes from the country where you are located. For example, from country Z, where YouTube is blocked, you go to country Y, where everything is fine with YouTube. Your Internet traffic from your phone makes its way to country Y, then returns back, and you watch YouTube. Accordingly, the censor of country Z, if he wants to block this destination, simply connects to this VPN, sees the destination, and blocks this path. ",
        text17normal5: "3. Blocking subnets. This happens super rarely, like imagine, blocking the whole of Europe at once, indiscriminately. : )",
        text17normal6: "Now, how do we work with steps 1-3.",
        text17normal7:
            "1. Since we initially understood what we were doing and wanted it. We use a special protocol (ShadowSocks), which is encrypted for the most common Internet traffic and it is impossible to understand that it is a VPN. Just some kind of encrypted traffic, which makes up 40-50% of all traffic on the Internet. ShadowSocks is doing well even in China.",
        text17normal8:
            "2. This was more difficult. Because no matter what the protocol is, you can always understand IP. And the problem with the way the main VPN services block is that the blocker connects as a user and essentially understands all destinations. We thought about what to do and came up with an idea. We have several solutions.",
        text17normal9: "Firstly, there is the OWN tariff - your own VPN, essentially thanks to it, the censor will not be able to understand your IP, since he will not be able to connect to it. ",
        text17normal10:
            "Secondly, if you are on a shared server, like regular VPNs, then a) we only give each user 3 servers (and not all like most VPNs) b) if blocking occurs, we understand it and robo tell you about it will inform c) to create a new server we need - 5 seconds, that is, we will offer you to connect to the new one approximately immediately",
        text17normal11:
            "3. Subnets. This, firstly, and most importantly, is not beneficial to the censor, because everything that is found in this direction is blocked. If you look at the experience of blocking, the result is that everything is simply closed, including the banks through which budget payments go. But, if this suddenly happens, we analyze the subnets and can redirect you in a working direction for free.",
        title18: "Problems with calls in Whats app and Telegram, what should I do? ",
        text18: "This is a problem with almost all standard VPN services: due to the fact that more than one user connects to the same server at the same time. If you do 1k1, then the cost will simply be more expensive. However, in this case the problem will be solved. We have such an opportunity - the own tariff, this is a separate server dedicated to you and your loved ones. ",
        title19: "How to Install VPN on a TV?",
        text19bold1: "3 Steps — How to Install VPN on Your TV",
        text19normal1: "Time: 5-20 minutes",
        text19normal2: "Requirements: a mouse (it's hard with the remote) and a USB flash drive",
        text19normal3: "This guide is suitable for devices running on Android TV: Philips, Sony, Xiaomi, Panasonic, and others.",
        text19bold2: "Download the VPN App to the Flash Drive",
        text19normal4: "1/ Download the V2RayNG client from GitHub and transfer it to the flash drive.",
        text19normal5: "2/ Save your VPN key from your personal account or mobile device onto the flash drive as a text file.",
        text19bold3: "Install V2RayNG on the TV",
        text19normal6: "1/ Connect the flash drive to the TV.",
        text19normal7: "2/ Install the CX File Explorer file manager via Google Play (find this app on your Android TV).",
        text19normal8: "3/ Open CX File Explorer —> go to the flash drive and launch V2RayNG (install V2RayNG).",
        text19bold4: "Run the VPN (Proceed as You Already Know)",
        text19normal9: "1/ Open the VPN text file from the flash drive (your key, the same one used on your mobile device), and copy it.",
        text19normal10: '2/ Launch V2RayNG, paste the link using the "Paste from Clipboard" option, and connect.',
        text19bold5: "Done!",
        text19normal11:
            "There may be slight (5-10%) deviations in the setup process depending on your TV model and settings; we recommend asking ChatGPT, Gemini, or YandexGPT (Alice) if something doesn’t work out.",
    },
};
