import React from "react";
import "./TariffNolimit.css";
import TariffesTemplate from "../TariffesTemplate/TariffesTemplate";
import FormLabel from "../FormLabel/FormLabel";
import { useSelector } from "react-redux";
import {
  parseTimestamp,
  getProfitValue,
  getTotalDiscountedValue,
} from "../../utils/helpers";
import PropTypes from "prop-types";
import { translations } from "../../utils/translations/translations";

function TariffNolimit({
  handler,
  error,
  value,
  setValue,
  onSetIsWithRoboBalance,
  isWithRoboBalance,
  setIsHidden,
  setIsMoreHidden,
  handleResPrice,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const prices = useSelector((state) => state.prices);
  const language = useSelector((state) => state.language);
  const [smart, setSmart] = React.useState(false);
  const [active, setActive] = React.useState();
  const [resPrice, setResPrice] = React.useState(0);
  const [fullResPrice, setFullResPrice] = React.useState(0);
  const userDate = /^\d+$/.test(currentUser.tariff)
    ? currentUser.endDate
    : currentUser.endActiveDate;
  const testDiscount = null;

  function addMonths(date, count) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + count);

    return `${newDate.getDate()}.${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : newDate.getMonth() + 1
    }.${newDate.getFullYear()}`;
  }

  const handleCheck = () => {
    setSmart((curr) => !curr);
  };

  const getDiscountedPrice = (price) => {
    return Math.round((price * (100 - parseInt(currentUser.discount))) / 100);
  };

  React.useEffect(() => {
    if (active === "Nolimit_1") {
      setFullResPrice(0);
    }
    if (active === "Nolimit_3") {
      smart
        ? setFullResPrice(prices.Nolimit_1[1] * 3)
        : setFullResPrice(prices.Nolimit_1[0] * 3);
    }
    if (active === "Nolimit_12") {
      smart
        ? setFullResPrice(prices.Nolimit_1[1] * 12)
        : setFullResPrice(prices.Nolimit_1[0] * 12);
    }
    if (active === "Nolimit_24") {
      smart
        ? setFullResPrice(prices.Nolimit_1[1] * 24)
        : setFullResPrice(prices.Nolimit_1[0] * 24);
    }
  }, [active, smart]);

  React.useEffect(() => {
    if (active === "Nolimit_1") {
      smart
        ? setResPrice(prices.Nolimit_1[1])
        : setResPrice(prices.Nolimit_1[0]);
    }
    if (active === "Nolimit_3") {
      smart
        ? setResPrice(prices.Nolimit_3[1] * 3)
        : setResPrice(prices.Nolimit_3[0] * 3);
    }
    if (active === "Nolimit_12") {
      smart
        ? setResPrice(prices.Nolimit_12[1] * 12)
        : setResPrice(prices.Nolimit_12[0] * 12);
    }
    if (active === "Nolimit_24") {
      smart
        ? setResPrice(prices.Nolimit_24[1] * 24)
        : setResPrice(prices.Nolimit_24[0] * 24);
    }
    if (currentUser.discount !== null && currentUser.discount !== 0) {
      setResPrice((curr) =>
        Math.round((curr * (100 - parseInt(currentUser.discount))) / 100)
      );
    }

    if (isWithRoboBalance) {
      const multiplyer = currentUser.isFirstRefUser ? 2 : 2;
      setResPrice((curr) =>
        Math.max(0, curr - currentUser.refBalance * 100 * multiplyer)
      );
    }
  }, [smart, active, isWithRoboBalance]);

  handleResPrice(resPrice);

  const isNolimitOneChecked = value * 1 === prices.Nolimit_1;
  const isNolimitThreeChecked = value * 1 === prices.Nolimit_3 * 3;
  const isNolimitYearChecked = value * 1 === prices.Nolimit_12 * 12;

  return (
    <TariffesTemplate
      currentClass="nolimit"
      buttonText={translations[language].appButton.pay}
      handler={handler}
      error={error}
      setIsHidden={setIsHidden}
      onSetIsWithRoboBalance={onSetIsWithRoboBalance}
      onChangeCheck={handleCheck}
      setIsMoreHidden={setIsMoreHidden}
      nolimitResPrice={resPrice}
      nolimitFullPrice={fullResPrice}
    >
      <div className="tariffes__content-nolimit">
        <FormLabel
          elementValue={
            smart === true ? prices.Nolimit_1[1] : prices.Nolimit_1[0]
          }
          tariffName="Nolimit_1"
          name="package"
          handler={(data) => {
            setValue(data);
            setActive("Nolimit_1");
          }}
          currentClass={`tariff-item-nolimit ${
            isNolimitOneChecked && "tariff-plan-checked"
          } ${smart && "smart"}`}
          title={translations[language].tariffes.month}
          text={
            active === "Nolimit_1"
              ? translations[language].tariffes.month1and3Text +
                addMonths(userDate, 1)
              : null
          }
          valueMain={
            (smart === true ? prices.Nolimit_1[1] : prices.Nolimit_1[0]) +
            translations[language].textTips.currencyMonth
          }
          isDiscounted={currentUser.discount ? true : false}
          currentUserDiscount={
            // isNolimitOneChecked ? currentUser.discount : null
            currentUser.discount === null && currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          valueSecondary={
            smart
              ? (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_1[1]) / 30)
                  : Math.round(prices.Nolimit_1[1] / 30)) +
                translations[language].textTips.currencyDay
              : (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_1[0]) / 30)
                  : Math.round(prices.Nolimit_1[0] / 30)) +
                translations[language].textTips.currencyDay
          }
          isProfit={currentUser.discount ? true : false}
          profit={currentUser.discount ? currentUser.discount : null}
        />
        <FormLabel
          elementValue={
            smart === true ? prices.Nolimit_3[1] : prices.Nolimit_3[0]
          }
          tariffName="Nolimit_3"
          name="package"
          handler={(data) => {
            setValue((data * 3).toString());
            setActive("Nolimit_3");
          }}
          currentClass={`tariff-item-nolimit ${
            isNolimitThreeChecked && "tariff-plan-checked"
          } ${smart && "smart"}`}
          title={translations[language].tariffes.month3}
          text={
            active === "Nolimit_3"
              ? translations[language].tariffes.month1and3Text +
                addMonths(userDate, 3)
              : null
          }
          valueMain={
            (smart === true ? prices.Nolimit_3[1] : prices.Nolimit_3[0]) +
            translations[language].textTips.currencyMonth
          }
          valueSecondary={
            // isNolimitThreeChecked
            //   ? getTotalDiscountedValue(
            //       prices.Nolimit_3,
            //       3,
            //       currentUser.discount
            //     ) + translations[language].textTips.currencyTotal
            //   : null
            smart
              ? (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_3[1]) / 30)
                  : Math.round(prices.Nolimit_3[1] / 30)) +
                translations[language].textTips.currencyDay
              : (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_3[0]) / 30)
                  : Math.round(prices.Nolimit_3[0] / 30)) +
                translations[language].textTips.currencyDay
          }
          // valueCount={
          //   smart
          //   ? ((currentUser.discount !== null && currentUser.discount !== '' ? Math.round(getDiscountedPrice(prices.Nolimit_3[1])*3) :  Math.round(prices.Nolimit_3[1]*3)) + translations[language].textTips.currencyTotal)
          //   : ((currentUser.discount !== null && currentUser.discount !== '' ? Math.round(getDiscountedPrice(prices.Nolimit_3[0])*3) :  Math.round(prices.Nolimit_3[0]*3)) + translations[language].textTips.currencyTotal)
          // }

          isDiscounted={false}
          // profitValue={
          //   isNolimitThreeChecked
          //     ? translations[language].tariffes.profit +
          //       getProfitValue(prices.Nolimit_1, prices.Nolimit_3, 3) +
          //       translations[language].textTips.currency
          //     : null
          // }
          currentUserDiscount={
            // isNolimitThreeChecked ? currentUser.discount : null
            currentUser.discount === null && currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          isProfit={currentUser.discount || active === "Nolimit_3"}
          isRecommended={active === "Nolimit_3" ? true : false}
          profit={
            currentUser.discount
              ? currentUser.discount
              : smart
              ? Math.round(
                  (1 - (prices.Nolimit_3[1] * 3) / (prices.Nolimit_1[1] * 3)) *
                    100
                )
              : Math.round(
                  (1 - (prices.Nolimit_3[0] * 3) / (prices.Nolimit_1[0] * 3)) *
                    100
                )
          }
        />
        <FormLabel
          elementValue={
            smart === true ? prices.Nolimit_12[1] : prices.Nolimit_12[0]
          }
          tariffName="Nolimit_12"
          name="package"
          handler={(data) => {
            setValue((data * 12).toString());
            setActive("Nolimit_12");
          }}
          currentClass={`tariff-item-nolimit ${
            isNolimitYearChecked && "tariff-plan-checked"
          } ${smart && "smart"}`}
          title={translations[language].tariffes.month12}
          text={
            active === "Nolimit_12"
              ? translations[language].tariffes.month12Text
              : null
          }
          valueMain={
            (smart === true ? prices.Nolimit_12[1] : prices.Nolimit_12[0]) +
            translations[language].textTips.currencyMonth
          }
          valueSecondary={
            smart
              ? (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_12[1]) / 30)
                  : Math.round(prices.Nolimit_12[1] / 30)) +
                translations[language].textTips.currencyDay
              : (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_12[0]) / 30)
                  : Math.round(prices.Nolimit_12[0] / 30)) +
                translations[language].textTips.currencyDay
          }
          isDiscounted={false}
          currentUserDiscount={
            // isNolimitYearChecked ? currentUser.discount : null
            currentUser.discount === null || currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          isProfit={currentUser.discount || active === "Nolimit_12"}
          isRecommended={active === "Nolimit_12" ? true : false}
          profit={
            currentUser.discount
              ? currentUser.discount
              : smart
              ? Math.round(
                  (1 -
                    (prices.Nolimit_12[1] * 12) / (prices.Nolimit_1[1] * 12)) *
                    100
                )
              : Math.round(
                  (1 -
                    (prices.Nolimit_12[0] * 12) / (prices.Nolimit_1[0] * 12)) *
                    100
                )
          }
        />
        <FormLabel
          elementValue={
            smart === true ? prices.Nolimit_24[1] : prices.Nolimit_24[0]
          }
          tariffName="Nolimit_24"
          name="package"
          handler={(data) => {
            setValue((data * 24).toString());
            setActive("Nolimit_24");
          }}
          currentClass={`tariff-item-nolimit ${
            isNolimitYearChecked && "tariff-plan-checked"
          } ${smart && "smart"}`}
          title={"2 года"}
          text={
            active === "Nolimit_24"
              ? translations[language].tariffes.month24Text
              : null
          }
          valueMain={
            (smart === true ? prices.Nolimit_24[1] : prices.Nolimit_24[0]) +
            translations[language].textTips.currencyMonth
          }
          valueSecondary={
            smart
              ? (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_24[1]) / 30)
                  : Math.round(prices.Nolimit_24[1] / 30)) +
                translations[language].textTips.currencyDay
              : (currentUser.discount !== null && currentUser.discount !== ""
                  ? Math.round(getDiscountedPrice(prices.Nolimit_24[0]) / 30)
                  : Math.round(prices.Nolimit_24[0] / 30)) +
                translations[language].textTips.currencyDay
          }
          isDiscounted={false}
          currentUserDiscount={
            // isNolimitYearChecked ? currentUser.discount : null
            currentUser.discount === null || currentUser.discount === 0
              ? null
              : parseInt(currentUser.discount)
          }
          isProfit={currentUser.discount || active === "Nolimit_24"}
          isRecommended={active === "Nolimit_24" ? true : false}
          profit={
            currentUser.discount
              ? currentUser.discount
              : smart
              ? Math.round(
                  (1 -
                    (prices.Nolimit_24[1] * 24) / (prices.Nolimit_1[1] * 24)) *
                    100
                )
              : Math.round(
                  (1 -
                    (prices.Nolimit_24[0] * 24) / (prices.Nolimit_1[0] * 24)) *
                    100
                )
          }
        />
      </div>
    </TariffesTemplate>
  );
}
TariffNolimit.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handler: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  setIsHidden: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default TariffNolimit;
