import { combineReducers } from 'redux';

export const reducer = combineReducers({
  currentUser: currentUserReducer,
  payment: paymentReducer,
  prices: pricesReducer,
  direction: currentDirectionReducer,
  currentCountry: currentCountryReducer,
  paymentUrl: paymentUrlReducer,
  nextTariff: nextTariffPlanReducer,
  isRedirected: isRedirectedReducer,
  isPaymentSmart: isPaymentSmartReducer,
  isPaymentTest: isPaymentTestReducer,
  ownCountry: ownCountryReducer,
  remoteData: remoteDataReducer,
  remoteHenchmens: remoteHenchmensReducer,
  language: languageReducer,
  loader: loaderReducer
});

function currentUserReducer(state = {}, action) {
  switch (action.type) {
    case 'USER/SET':
      return action.payload;
    default:
      return state;
  }
}
function paymentReducer(state = 0, action) {
  switch (action.type) {
    case 'PAYMENT/SET':
      return action.payload;
    default:
      return state;
  }
}
function paymentUrlReducer(state = 'success', action) {
  switch (action.type) {
    case 'PAYMENT_URL/SET':
      return action.payload;
    default:
      return state;
  }
}

function currentCountryReducer(state = 0, action) {
  switch (action.type) {
    case 'COUNTRY/SET':
      return action.payload;
    default:
      return state;
  }
}

function pricesReducer(state = {}, action) {
  switch (action.type) {
    case 'PRICES/SET':
      return action.payload;
    default:
      return state;
  }
}

function currentDirectionReducer(state = true, action) {
  switch (action.type) {
    case 'DIRECTION/SET':
      return action.payload;
    default:
      return state;
  }
}

function nextTariffPlanReducer(state = '', action) {
  switch (action.type) {
    case 'TARIFF/SET':
      return action.payload;
    default:
      return state;
  }
}

function isRedirectedReducer(state = false, action) {
  switch (action.type) {
    case 'REDIRECT/SET':
      return action.payload;
    default:
      return state;
  }
}

function isPaymentSmartReducer(state = 'regular', action) {
  switch(action.type) {
    case 'SMART/SET':
      return action.payload;
    default:
      return state;
  }
}

function isPaymentTestReducer(state = 0, action) {
  switch(action.type) {
    case 'TEST/SET':
      return action.payload;
    default:
      return state;
  }
}

function ownCountryReducer(state = 0, action) {
  switch(action.type) {
    case 'OWNCOUNTRY/SET':
      return action.payload;
    default:
      return state;
  }
}

function remoteDataReducer(state = {}, action) {
  switch(action.type) {
    case 'REMOTEDATA/SET':
      return action.payload;
    default:
      return state;
  }
}

function remoteHenchmensReducer(state = {}, action) {
  switch(action.type) {
    case 'REMOTEHENCHMENS/SET':
      return action.payload;
    default:
      return state;
  }
}

function languageReducer(state = 'ru', action) {
  switch (action.type) {
    case 'LANGUAGE/SET':
      return action.payload;
    default:
      return state;
  }
}

function loaderReducer(state = false, action) {
  switch (action.type) {
      case "SHOW_LOADER":
          return { ...state, loading: true };
      case "HIDE_LOADER":
          return { ...state, loading: false };
      default:
          return state;
  }
}

